import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

// const isProduction = process.env.REACT_APP_CUSTOM_ENV === "production";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: localStorage?.getItem("lang") || "en-US",
    fallbackLng: {
      "en-US": ["en-US"],
      ja: ["ja"],
      default: ["en-US"],
    },
    keySeparator: ".",
    debug: false,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

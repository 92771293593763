import styled from "styled-components/macro";
import { css } from "styled-components";

import { COLORS, QUERIES, BORDER_RADIUS, centerStyle } from "configs/styles/common";

export const LongLat = styled.div`
  ${centerStyle};
  gap: 5px;
  width: 70%;
  height: 20px;
  background-color: ${COLORS.LIGHT.White};
  box-shadow: 0 3px 19px rgb(0 0 0 / 15%);
  border-radius: 30px;
  font-size: 10px;
  line-height: 14px;
  color: ${COLORS.DARK.Main};
  position: absolute;
  bottom: 3px;
  left: 50%;
  transform: translateX(-50%);

  @media ${QUERIES.upToTablet} {
    width: 80% !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
  }

  @media ${QUERIES.upToMobile} {
    display: flex;
    flex-direction: column;
    height: 40px;
    width: 60% !important;
  }

  ${(props) =>
    props.isPdf &&
    css`
      height: 40px;
      font-size: 16px;
      width: 50%;
      box-shadow: none;
      margin-bottom: 10px;
    `}
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${COLORS.LIGHT.Background};
  position: relative;
  display: grid;
  place-content: center;
  border-radius: ${BORDER_RADIUS.M};
  color: ${COLORS.DARK.Main};
  text-align: center;
  padding: 20px;
`;

export const ErrorWrapper = styled(LoadingWrapper)`
  color: ${COLORS.INPUT.Error};
`;

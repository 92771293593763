import React, { useState } from "react";
import Cookies from "js-cookie";

import * as Styled from "./CookieBanner.style";

export default function CookieBanner() {
  const [isCookieAccepted, setIsCookieAccepted] = useState(Cookies.get("AcceptCookie") === "true");
  const [isCookieRefused, setIsCookieRefused] = useState(Cookies.get("RefuseCookie") === "true");

  function onAcceptCookieClick() {
    setIsCookieAccepted(true);
    Cookies.set("AcceptCookie", "true", { expires: 3 });
  }

  function onRefuseClick() {
    setIsCookieRefused(true);
    Cookies.set("RefuseCookie", "true", { expires: 3 });
  }

  if (isCookieAccepted || isCookieRefused) return null;

  return (
    <Styled.Wrapper>
      <Styled.InnerBox>
        <Styled.CookieText>
          GeoX website uses cookies to customize and improve the content shown to you, making sure you get the best
          experience on our website.
        </Styled.CookieText>

        <Styled.CookieLink to="cookie-notice">Learn more</Styled.CookieLink>
      </Styled.InnerBox>

      <Styled.RefuseButton onClick={onRefuseClick} />

      <Styled.CookieButton onClick={onAcceptCookieClick}>Accept</Styled.CookieButton>
    </Styled.Wrapper>
  );
}

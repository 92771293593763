import React from "react";
import { control } from "react-validation";
import styled, { css } from "styled-components";

export const COLORS = {
  INPUT: {
    Placeholder: "#b2b1b1",
    Error: "#d12727",
    ErrorBg: "#ffe1e1",
    Success: "#22c55e",
    AccentPlaceholder: "#877a7a",
  },
  ACCENT: "#ffcd00",
  DARK: {
    Main: "#23242a",
    Secondary: "#54565a",
  },
  LIGHT: {
    White: "#fff",
    Background: "#faf9f5",
    Slate: "#f5f5f4ff",
    SlateDarker: "#e2e8f0",
  },
  GREY: {
    Main: "#cbc8c8",
    Secondary: "#d9d9d9",
    Form: "#808080",
  },
};

export const BREAKPOINTS = {
  mobileMax: 550,
  tabletMax: 1100,
  laptopMax: 1500,
};

export const QUERIES = {
  upToMobile: `(max-width: ${BREAKPOINTS.mobileMax}px)`,
  upToTablet: `(max-width: ${BREAKPOINTS.tabletMax}px)`,
  upToLaptop: `(max-width: ${BREAKPOINTS.laptopMax}px)`,
};

export const Button = styled.button`
  background: ${COLORS.DARK.Main};
  border-radius: 1.04rem;
  font-family: "Work Sans", sans-serif;
  font-weight: 700;
  font-size: 1.33rem;
  line-height: 1.58rem;
  width: 25.91rem;
  height: 3.125rem;
  outline: none;
  border: none;
  color: ${COLORS.LIGHT.White};

  &:focus {
    outline: none;
  }

  @media ${QUERIES.upToTablet} {
    width: 100%;
    height: 54px;
    font-size: 20px;
  }
`;

export const baseTitleStyle = css`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: ${COLORS.DARK.Secondary};
  white-space: nowrap;
  margin: 0;

  @media (max-width: 739px) {
    font-size: 18px;
    line-height: 20px;
  }
`;

const GenericStyledTextField = ({ withoutPadding, error, isChanged }) => css`
  position: relative;
  padding: ${withoutPadding ? 0 : "0.29rem 0"};
  width: 100%;
  border: none;
  border-bottom: 0.1112rem solid ${error && isChanged ? "#ae3e3e" : COLORS.DARK.Main};
  background-color: transparent;
  font-weight: 500;
  line-height: 0.95rem;
  margin-bottom: 1.46rem;
  appearance: none;
  border-radius: 0;

  &::placeholder {
    color: #bdbdbd;
  }

  &:focus {
    outline: none;
  }

  @media screen and (max-width: 1270px) {
    margin-bottom: 1.16rem;
  }

  @media ${QUERIES.upToTablet} {
    font-size: 20px;
    line-height: 23px;
  }
`;

export const StyledInput = styled.input`
  ${GenericStyledTextField}
`;
export const StyledTextArea = styled.textarea`
  ${GenericStyledTextField}
`;

const InputField = styled.div`
  position: relative;

  & .form-error {
    position: absolute;
    bottom: 10px;
    left: 0;
    padding: 0;
    color: #ae3e3e;
    font-size: 12px;
    line-height: 14px;
  }

  @media screen and (max-width: 1770px) {
    & .form-error {
      bottom: 5px;
    }
  }

  @media screen and (max-width: 1270px) {
    & .form-error {
      bottom: -4px;
      font-size: 10px;
    }
  }

  @media ${QUERIES.upToTablet} {
    padding-bottom: 10px;

    & .form-error {
      bottom: ${(props) => (props.isLoginPage ? "9px" : "24px")};
      font-size: 12px;
      line-height: 12px;
    }
  }
`;

const CustomInput = ({ error, isChanged, isUsed, isLoginPage, ...props }) => (
  <InputField isLoginPage={isLoginPage}>
    <StyledInput error={error} isChanged={isChanged} {...props} />
    {isChanged && isUsed && error}
  </InputField>
);

export const ValidationInput = control(CustomInput);

export const StyledInputClear = styled.input`
  padding: 7px 0;
  width: 100%;
  border: none;
  border-bottom: 2px solid #270093;
  background-color: transparent;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 40px;

  &::placeholder {
    color: #bdbdbd;
  }

  &:focus {
    outline: none;
  }
`;

export const ModalButton = styled(Button)`
  width: 239px;
  height: 55px;
  background: ${COLORS.ACCENT};
  box-shadow: 0 4px 22px rgb(0 0 0 / 14%);
  border-radius: 15px;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  text-transform: capitalize;
  color: ${COLORS.DARK.Main};
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 1.6667rem 2.5rem;
  border: none;
  border-radius: 30px;
  background-color: white;

  @media ${QUERIES.upToTablet} {
    border-radius: 20px;
  }
`;

export const ModalTitle = styled.h3`
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  color: ${COLORS.DARK.Main};
  margin-bottom: 0;
`;

export const containerWidth = () => {
  switch (true) {
    case window.innerWidth < 940:
      return "calc(100vw - 60px * 2)";
    case window.innerWidth <= 1100:
      return "calc(100vw - 80px * 2)";
    case window.innerWidth <= 1500:
      return "calc(100vw - 100px * 2)";
    case window.innerWidth <= 1920:
      return "calc(100vw - 160px * 2)";
    default:
      return "calc(100vw - 250px * 2)";
  }
};

export const mobileContainerWidth = "86vw";

export const mobileContainerStyle = css`
  max-width: 530px;
  padding: 0 20px;
`;

export const Container = styled.div`
  max-width: ${containerWidth()};
  margin: 0 auto;
  width: 100%;
  display: ${({ contents }) => (contents?.laptop ? "contents" : "block")};

  @media ${QUERIES.upToTablet} {
    display: ${({ contents }) => (contents?.tablet ? "contents" : "block")};
  }

  @media ${QUERIES.upToMobile} {
    ${mobileContainerStyle}
  }
`;

export const BORDER_RADIUS = {
  XS: "6px",
  S: "10px",
  M: "16px",
  MODAL: "8px",
  ICON: "4px",
};

export const headerHeight = {
  laptop: 70,
  tablet: 100,
  mobile: 75,
};

export const singleSearchMobileQuery = "(max-width: 690px)";

export const BOX_SHADOW = {
  LIGHT: "2px 2px 10px 0 rgb(0 0 0 / 10%)",
  REGULAR: "0 3px 7px rgba(0, 0, 0, 0.11)",
  BOLD: "0 4px 14px rgba(0 0 0 / 13%)",
  ICON_BUTTON: "0 0 0.333rem rgb(0 0 0 / 16%)",
};

export const FONTS = {
  Main: "'Work Sans', sans-serif",
  Title: "Bitter, serif",
};

export const WEIGHT = {
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
};

export const baseButtonStyle = css`
  cursor: pointer;
  display: grid;
  place-content: center;
  border: none;
  padding: 0;
  width: 100%;
  background-color: transparent;
  color: ${COLORS.DARK.Main};
  font-weight: ${WEIGHT.medium};

  &:focus {
    outline: none;
  }
`;

export const centerStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const iconButtonStyle = css`
  ${baseButtonStyle};
  background-color: ${COLORS.LIGHT.White};
  box-shadow: ${BOX_SHADOW.ICON_BUTTON};
  border-radius: ${BORDER_RADIUS.XS};
  width: 45px;
  height: 40px;
  transition: transform 300ms;

  &:hover {
    transform: scale(1.03);
  }

  &:focus {
    outline: 1px solid ${COLORS.DARK.Main};
  }
`;

import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";

import { baseButtonStyle, COLORS } from "configs/styles/common";
import cancelSVG from "assets/icons/close_icon_small.svg";

const slideIn = keyframes`
  from {
    transform: translateY(100%);
  }
  
  to {
    transform: translateY(0%);
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  isolation: isolate;
  z-index: 999;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: ${COLORS.LIGHT.White};
  color: ${COLORS.DARK.Main};
  filter: drop-shadow(0 4px 15px rgb(0 0 0 / 5%));
  animation: ${slideIn} 500ms ease-out backwards;
  animation-delay: 500ms;

  @media (max-width: 600px) {
    flex-direction: row-reverse;
  }
`;

export const InnerBox = styled.div`
  flex-grow: 1;
  margin: 25px;
  display: flex;
  align-items: center;
  gap: 25px;
  position: relative;

  @media (max-width: 600px) {
    display: block;
    margin: 10px;
    margin-right: 50px;
  }
`;

export const RefuseButton = styled.button`
  ${baseButtonStyle};
  position: absolute;
  right: 0;
  top: 0;
  background-color: ${COLORS.ACCENT};
  background-image: url(${cancelSVG});
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transition: background-color 200ms;

  & > svg {
    path {
      stroke: ${COLORS.DARK.Secondary};
    }
  }

  &:hover {
    background-color: ${COLORS.LIGHT.White};
  }

  @media (max-width: 600px) {
    left: 0;
  }

  &::after {
    --tap-increment: -12px;

    position: absolute;
    content: "";
    top: var(--tap-increment);
    left: var(--tap-increment);
    right: var(--tap-increment);
    bottom: var(--tap-increment);
  }
`;

export const CookieText = styled.p`
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  text-align: justify;

  @media (max-width: 600px) {
    display: inline;
    font-size: 12px;
    line-height: 18px;
  }
`;

export const CookieLink = styled(Link)`
  color: ${COLORS.DARK.Secondary};
  font-size: 12px;
  line-height: 17px;
  text-decoration: underline;

  @media (max-width: 600px) {
    margin-left: 15px;
  }
`;

export const CookieButton = styled.button`
  flex-grow: 0;
  ${baseButtonStyle};
  max-width: 160px;
  width: 100%;
  align-self: stretch;
  background-color: ${COLORS.ACCENT};
  color: ${COLORS.DARK.Secondary};
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  box-shadow: 0 3px 7px rgb(0 0 0 / 11%);

  @media (max-width: 600px) {
    max-width: 100px;
  }
`;

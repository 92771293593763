import Cookies from "js-cookie";
import React from "react";
import { Redirect, withRouter } from "react-router-dom";

import BaseRoute from "./BaseRoute";

function PublicRoute({ ...rest }) {
  return Cookies.get("isLogin") === "true" ? (
    <Redirect to={{ pathname: "/single-search", state: { from: rest.location } }} />
  ) : (
    <BaseRoute {...rest} />
  );
}

export default withRouter(PublicRoute);

import React, { useState, useRef, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import { useAlert } from "react-alert";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { useMediaQuery } from "react-responsive";

import API from "api/apiClient";
import { SEO } from "configs/seo";
import { ValidationInput } from "configs/styles/common";
import { required, email } from "services/constants/VALIDATOR";
import { GOOGLE_CLIENT_ID, LINKEDIN_CLIENT_ID, S3 } from "services/constants";
import { Header } from "components/presenters";
import { Mixpanel } from "services/helpers/common";

import NewAccount from "./components/NewAccount";
import Overlay from "./overlay/WelcomeOverlay";
import * as Styled from "./styles";

const google = S3 + "assets/google-social.svg";
const linkedin = S3 + "assets/linkedin-social.png";

function Login({ history }) {
  const form = useRef();
  const alert = useAlert();
  const params = new URLSearchParams(window.location.search);
  const getStarted = !!params.get("get-started");
  const isMobile = useMediaQuery({ maxWidth: 560 });
  const isTablet = useMediaQuery({ minWidth: 561, maxWidth: 1100 });
  const isDesktop = useMediaQuery({ minWidth: 1101 });

  const { linkedInLogin } = useLinkedIn({
    clientId: LINKEDIN_CLIENT_ID,
    redirectUri: `${window.location.origin}/linkedin`,
    onSuccess: (code) => {
      onLinkedinLoginSuccess(code);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [error, setError] = useState(null);
  const [isRegisterFormShown, setIsRegisterFormShown] = useState(false);
  const [isLoginFormShown, setIsLoginFormShown] = useState(true);

  const [state, setState] = useState({
    email: "",
    password: "",
    isRevealPassword: false,
  });

  useEffect(() => {
    if (isDesktop) {
      setIsRegisterFormShown(true);
    }
  }, [isDesktop]);

  const handleChange = (event) => {
    setError(null);
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const alertAfterLogin = (message) => {
    alert.success(message);
  };

  const redirectToSingleSearch = () => {
    Overlay();
    history.push("/single-search");
  };

  const handleSubmitClick = (event) => {
    event.preventDefault();
    form.current.validateAll();

    API.login(state.email, state.password)
      .then(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: "sign_in" });
        alertAfterLogin("Login Successfully!");

        Mixpanel.identify(state.email);
        Mixpanel.track("Successful regular login");
        Mixpanel.people.set({
          $email: state.email,
        });

        redirectToSingleSearch();
      })
      .catch((error) => {
        Mixpanel.reset();
        Mixpanel.track("Unsuccessful regular login", { "From email": state.email });
        setError(error.response.data.msg);

        if (isMobile && error.response?.data?.msg) {
          alert.error(error.response.data.msg);
        }
      });
  };

  const togglePassword = () => {
    setState((prevState) => ({
      ...prevState,
      isRevealPassword: !state.isRevealPassword,
    }));
  };

  function onLinkedinLoginSuccess(code) {
    if (!code) return;
    API.connectToLinkedin(code, `${window.location.origin}/linkedin`).then(
      (status) => {
        if (status === 200) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({ event: "sign_in" });
          alertAfterLogin("Login Successfully!");
          redirectToSingleSearch();
        }
      },
      (err) => alert.show(err.response.data.msg)
    );
  }

  const responseGoogle = (response) => {
    if (response.code) {
      API.connectToGoogle(response.code).then((status) => {
        if (status === 200) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({ event: "sign_in" });
          redirectToSingleSearch();
        }
      });
    }
  };

  function onBackButtonClick() {
    setIsRegisterFormShown((prevState) => !prevState);
    setIsLoginFormShown((prevState) => !prevState);
  }

  return (
    <>
      <SEO title={"Login"} />
      <Header />

      <Styled.Login>
        <Styled.Wrapper>
          {(isRegisterFormShown || getStarted) && <NewAccount onBackButtonClick={onBackButtonClick} />}

          {isLoginFormShown && !getStarted && (
            <Styled.RightSide>
              <Styled.Title>Login</Styled.Title>

              <Styled.WelcomeMessage>Hi, good to see you again!</Styled.WelcomeMessage>

              <Styled.FormTitle marginBottom="47px">Please, enter a valid email address & password</Styled.FormTitle>

              <Styled.StyledForm onSubmit={handleSubmitClick} ref={form}>
                <ValidationInput
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={state.email}
                  onChange={handleChange}
                  validations={[required, email]}
                  withoutPadding
                  isLoginPage
                />

                <Styled.InputWrapper mb>
                  <Styled.Input
                    type={state.isRevealPassword ? "text" : "password"}
                    name="password"
                    placeholder="Password"
                    value={state.password}
                    onChange={handleChange}
                    validations={[required]}
                  />

                  <Styled.ResetPassword
                    to="/reset-password-form"
                    onClick={() => {
                      Mixpanel.track("Forgot password");
                    }}
                  >
                    Forgot Password?
                  </Styled.ResetPassword>

                  <Styled.TogglePassword onClick={() => togglePassword()}>
                    {state.isRevealPassword ? (
                      <span className="fas fa-eye field-icon toggle-password" />
                    ) : (
                      <span className="fas fa-eye-slash field-icon toggle-password" />
                    )}
                  </Styled.TogglePassword>
                </Styled.InputWrapper>

                <Styled.LoginButton type="submit">Login</Styled.LoginButton>

                <Styled.LoginSeparator>- or login with -</Styled.LoginSeparator>
              </Styled.StyledForm>

              <Styled.SocialLogin>
                <GoogleLogin
                  clientId={GOOGLE_CLIENT_ID}
                  render={(renderProps) => (
                    <Styled.SocialButton bg="white" onClick={renderProps.onClick} disabled={renderProps.disabled}>
                      <Styled.SocialImage src={google} /> Google
                    </Styled.SocialButton>
                  )}
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  responseType="code"
                  accessType="offline"
                  cookiePolicy={"single_host_origin"}
                />

                <Styled.SocialButton bg="#006699" color="white" onClick={linkedInLogin}>
                  <Styled.SocialImage src={linkedin} /> Linkedin
                </Styled.SocialButton>
              </Styled.SocialLogin>

              {!error || isMobile || isTablet ? (
                <Styled.TermsAndPolicy>
                  By signing in I agree to GeoX <Link to="terms">Terms of Service</Link> &
                  <Link to="privacy"> Privacy Policy</Link>.
                </Styled.TermsAndPolicy>
              ) : (
                <Styled.ErrorMessage>{error}</Styled.ErrorMessage>
              )}

              {isTablet && error && <Styled.ErrorMessage>{error}</Styled.ErrorMessage>}

              {(isMobile || isTablet) && (
                <Styled.SwitchButton
                  onClick={() => {
                    setIsRegisterFormShown(true);
                    setIsLoginFormShown(false);
                  }}
                >
                  New Account?
                </Styled.SwitchButton>
              )}
            </Styled.RightSide>
          )}
        </Styled.Wrapper>
      </Styled.Login>
    </>
  );
}

export default withRouter(Login);

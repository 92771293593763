import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import Form from "react-validation/build/form";

import { S3 } from "services/constants/API";

import {
  BORDER_RADIUS,
  BOX_SHADOW,
  Button as StyledButton,
  COLORS,
  QUERIES,
  StyledInput,
  WEIGHT,
} from "../../configs/styles/common";

const hero = S3 + "assets/hero2.png";

export const Login = styled.section`
  height: calc(100vh - 51px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${hero}), linear-gradient(180deg, rgb(255 205 0 / 30%) 0%, rgb(248 247 246 / 30%) 100%);
  background-size: cover;

  @media ${QUERIES.upToTablet} {
    height: auto;
    min-height: calc(100vh - 100px);
    padding: 30px 0;
  }
`;

export const Wrapper = styled.div`
  width: 62rem;
  height: 580px;
  box-shadow: 0 0.2222rem 2.1667rem rgb(0 0 0 / 14%);
  border-radius: 5.5556rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;

  @media ${QUERIES.upToTablet} {
    flex-direction: column;
    width: 90%;
    height: auto;
    border-radius: 20px;
  }
`;

export const Input = styled(StyledInput)`
  border-color: black;
  margin-bottom: 1.7722rem;
  padding: 0;

  @media screen and (max-width: 1269px) {
    margin-bottom: 1.6666rem;
  }

  @media ${QUERIES.upToTablet} {
    height: 44px;

    & .form-error {
      bottom: 7px;
    }
  }
`;

export const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  background-color: white;
  padding: 40px 48px 52px;
  border-radius: 30px 0 0 30px;

  @media ${QUERIES.upToTablet} {
    border-radius: 10px;
    width: 100%;

    & ${Input} {
      margin-bottom: 30px;
    }
  }
`;

export const RightSide = styled(LeftSide)`
  position: relative;
  width: 50%;
  border-radius: 30px;
  box-shadow: ${BOX_SHADOW.BOLD};

  @media ${QUERIES.upToTablet} {
    border-radius: 10px;
    width: 100%;
  }
`;

export const Title = styled.h2`
  font-weight: ${WEIGHT.semiBold};
  font-size: 38px;
  line-height: 38px;
  color: ${COLORS.DARK.Main};
  margin-bottom: 10px;

  @media ${QUERIES.upToTablet} {
    font-size: 30px;
    line-height: 126.5%;
  }
`;

export const WelcomeMessage = styled.h5`
  font-size: 18px;
  line-height: 132.3%;
  color: ${COLORS.DARK.Main};
  margin-bottom: 10px;

  @media ${QUERIES.upToTablet} {
    font-size: 16px;
  }
`;

export const StyledForm = styled(Form)`
  position: relative;
`;

export const Spacer = styled.div`
  height: 45px;
`;

export const Button = styled(StyledButton)`
  width: 100%;
  height: 55px;
  border-radius: ${BORDER_RADIUS.M};

  @media ${QUERIES.upToTablet} {
    margin-bottom: 30px;
    width: 100%;
  }
`;

export const LoginButton = styled(StyledButton)`
  width: 100%;
  height: 55px;
  margin-top: 20px;
  border-radius: ${BORDER_RADIUS.M};
  background: ${COLORS.ACCENT};
  color: ${COLORS.DARK.Main};
  box-shadow: ${BOX_SHADOW.REGULAR};

  @media ${QUERIES.upToTablet} {
    width: 100%;
  }
`;

export const SocialLogin = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${QUERIES.upToTablet} {
    flex-direction: column;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 0.625rem;

  @media ${QUERIES.upToTablet} {
    margin-bottom: ${({ mb }) => mb && "65px"};
  }
`;

export const TogglePassword = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;

  @media ${QUERIES.upToTablet} {
    padding: 15px;
    font-size: 16px;
    top: -10px;
    right: -10px;
  }
`;

export const ResetPassword = styled(Link)`
  position: absolute;
  top: 1.75rem;
  right: 0.208rem;
  font-weight: normal;
  font-size: 0.66rem;
  line-height: 0.79rem;
  text-decoration-line: underline;
  color: black;

  &:hover {
    color: black;
  }

  @media screen and (max-width: 1269px) {
    top: 2.55rem;
  }

  @media ${QUERIES.upToTablet} {
    width: 100%;
    text-align: center;
    font-size: 16px;
    right: 50%;
    top: 83px;
    transform: translateX(50%);
  }
`;

export const LoginSeparator = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 0.8889rem;
  line-height: 1.0556rem;
  margin: 1.8889rem 0;

  @media ${QUERIES.upToTablet} {
    font-size: 16px;
    line-height: 19px;
  }
`;

export const FormTitle = styled.div`
  font-size: 1rem;
  line-height: 166.8%;
  color: ${COLORS.DARK.Main};
  margin-bottom: ${(props) => props.marginBottom};

  @media ${QUERIES.upToTablet} {
    font-size: 18px;
    line-height: 166.8%;
  }
`;

export const SocialButton = styled(Button)`
  background-color: ${(props) => props.bg};
  font-size: 1rem;
  line-height: 1.1667rem;
  text-transform: capitalize;
  margin-bottom: 0.625rem;
  width: calc(50% - 4px);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.2778rem;
  border: none;
  box-sizing: border-box;
  color: ${(props) => (props.color ? props.color : "black")};
  box-shadow: 0 0.2222rem 1.2222rem rgb(0 0 0 / 14%);

  @media ${QUERIES.upToTablet} {
    width: 100%;
    height: 54px;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 5px;
  }
`;

export const SocialImage = styled.img`
  margin-right: 0.58rem;
  height: 57%;
`;

export const ErrorMessage = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 28.5rem;
  min-height: 2.5rem;
  background: #fff;
  box-shadow: 0 0.2222rem 2.1667rem rgb(0 0 0 / 14%);
  border-radius: 1.3889rem;
  font-weight: 500;
  font-size: 0.8789rem;
  line-height: 1.0556rem;
  padding: 8px 4px;
  color: #ae3e3e;
  display: grid;
  place-items: center;
  text-align: center;
  bottom: 20px;

  @media ${QUERIES.upToTablet} {
    width: 85%;
    font-size: 14px;
    line-height: 20px;
    padding: 8px 10px;
  }
`;

export const SwitchButton = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-decoration-line: underline;
  text-transform: capitalize;
  margin-top: 25px;
  cursor: pointer;
`;

export const Text = styled.p`
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  text-transform: uppercase;
`;

export const ErrorText = styled(Text)`
  text-transform: lowercase;
  color: #ae3e3e;
  position: absolute;
  bottom: -14%;
  left: 0.5rem;

  @media ${QUERIES.upToTablet} {
    bottom: -12px;
    font-size: 14px;
    line-height: 14px;
  }
`;

export const TermsAndPolicy = styled.div`
  text-align: center;
  font-weight: 700;
  font-size: 0.857rem;
  line-height: 1rem;
  color: #cdcdcd;

  & > a {
    color: inherit;
    text-decoration: underline;
  }

  @media (max-width: 940px) {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
`;

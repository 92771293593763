import React from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";

import { ReactComponent as CloseIcon } from "assets/icons/close_icon_dark.svg";
import API from "api/apiClient";

import { Button } from "../../../Button";
import * as Styled from "./style";

export function MobileMenu({ open, close }) {
  const isAuthorized = Cookies.get("isLogin") === "true";
  const { t } = useTranslation();
  const history = useHistory();
  const alert = useAlert();

  function logOut() {
    API.logout().then((data) => {
      if (data.status === 200) {
        Cookies.remove("isAdmin");
        history.push("/home");
      }
      alert.show(data.msg);
    });
  }

  return (
    <Styled.MenuPopup open={open} modal closeOnDocumentClick closeOnEscape onClose={close} lockScroll>
      <Button
        onClick={close}
        variant="ghost"
        style={{ maxWidth: "44px", height: "44px", alignSelf: "end" }}
        data-testid="close-menu"
      >
        <CloseIcon />
      </Button>

      <Styled.NavigationList>
        {!isAuthorized ? (
          <>
            <li>
              <Styled.NavigationLink to="/home">{t("Home")}</Styled.NavigationLink>
            </li>

            <li>
              <Styled.NavigationLink to="/login" onClick={close}>
                {t("Log In")}
              </Styled.NavigationLink>
            </li>
          </>
        ) : (
          <>
            <li>
              <Styled.NavigationLink to="/single-search">{t("Search")}</Styled.NavigationLink>
            </li>

            <li>
              <Button
                onClick={logOut}
                variant="contained"
                style={{ maxWidth: "150px", margin: "0 auto", marginTop: "48px" }}
              >
                {t("Log Out")}
              </Button>
            </li>
          </>
        )}
      </Styled.NavigationList>
    </Styled.MenuPopup>
  );
}

import React from "react";
import { v4 as uuid } from "uuid";

import styles from "./Arrange.module.css";

export default function Arrange({ inputTextFrom, inputTextTo, labelText, type, isDate }) {
  const id = uuid();
  return (
    <>
      <label htmlFor={id}>{labelText}</label>
      <div className={styles.inputGroup}>
        <input
          type={isDate ? "date" : "text"}
          id={id}
          data-arrange="from"
          data-formtype={type}
          className={"arrangeInputs " + styles.input}
          placeholder={inputTextFrom}
        />
        <input
          type={isDate ? "date" : "text"}
          data-arrange="to"
          data-formtype={type}
          className={"arrangeInputs " + styles.input}
          placeholder={inputTextTo}
        />
      </div>
    </>
  );
}

import { useEffect, useState } from "react";

export default function useLoadMore(array, length = 30) {
  const [isLoaded, setIsLoaded] = useState(true);

  useEffect(() => {
    if (array.length % length !== 0 || array.length === 0) {
      setIsLoaded(true);
    } else {
      setIsLoaded(false);
    }
  }, [array, length]);

  return [isLoaded];
}

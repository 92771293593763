import React, { memo, useCallback, useState } from "react";
import { GoogleMap, Polygon, useJsApiLoader } from "@react-google-maps/api";

import { MAP_CONTAINER_STYLE, MAP_LOADER, POLYGON_OPTIONS } from "services/constants";
import { MAP_TYPE } from "app/providers/business/MapProvider";

import { BuildingMarkers } from "./components/BuildingMarkers";
import { CustomGoogleMapWrapper } from "../../style";
import { MapError, MapLoader, NoMapLocation } from "./components";
import * as Styled from "./style";

function BuildingsMap({ building_points, parcel_boundary, centerCoords, isLoading, selectBuilding, isPdf = false }) {
  const [polygonsPositions, setPolygonPosition] = useState([]);

  const { isLoaded, loadError } = useJsApiLoader(MAP_LOADER);

  const onLoad = useCallback(
    function onLoad(mapInstance) {
      mapInstance.setMapTypeId(MAP_TYPE.SATELLITE);

      const bounds = new window.google.maps.LatLngBounds();

      const nextPolygonPosition = parcel_boundary.map((parcel) => {
        return parcel.map((polygon) => {
          bounds.extend({ lat: polygon[0], lng: polygon[1] });
          return { lat: polygon[0], lng: polygon[1] };
        });
      });

      setPolygonPosition(nextPolygonPosition);

      mapInstance.fitBounds(bounds);
    },
    [parcel_boundary]
  );

  const onMarkerClick = useCallback(
    function (index) {
      selectBuilding(index);
    },
    [selectBuilding]
  );

  if (loadError) {
    return <MapError />;
  }

  if (isLoading) {
    return <MapLoader />;
  }

  if (!centerCoords.lat || !centerCoords.lng) {
    return <NoMapLocation />;
  }

  if (!isLoaded) {
    return null;
  }

  const MAP_OPTIONS = {
    mapTypeControl: false,
    zoomControl: true,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: true,
    minZoom: 3,
    rotateControlOptions: {
      position: window?.google?.maps?.ControlPosition?.LEFT_BOTTOM,
    },
    zoomControlOptions: {
      position: window?.google?.maps?.ControlPosition?.RIGHT_BOTTOM,
    },
  };

  const PDF_MAP_OPTIONS = {
    mapTypeControl: false,
    zoomControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
  };

  return (
    <CustomGoogleMapWrapper>
      <GoogleMap
        id="buildings-map"
        onLoad={onLoad}
        mapContainerStyle={MAP_CONTAINER_STYLE}
        center={centerCoords}
        zoom={19}
        options={isPdf ? PDF_MAP_OPTIONS : MAP_OPTIONS}
      >
        {!!building_points.length && (
          <BuildingMarkers onMarkerClick={onMarkerClick} buildingPoints={building_points} isPdf={isPdf} />
        )}

        {!!polygonsPositions.length &&
          polygonsPositions.map((position, index) => {
            return <Polygon key={index} paths={position} options={POLYGON_OPTIONS} />;
          })}

        {centerCoords.lat && isPdf && (
          <Styled.LongLat data-testid="lat-lng" isPdf={isPdf}>
            <span>{centerCoords.lat},</span> <span>{centerCoords.lng}</span>
          </Styled.LongLat>
        )}
      </GoogleMap>
    </CustomGoogleMapWrapper>
  );
}

export default memo(BuildingsMap);

import React from "react";
import styled from "styled-components/macro";

import { COLORS, FONTS, QUERIES, WEIGHT } from "configs/styles/common";

const Heading = styled.h1`
  font-family: ${FONTS.Title};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  font-weight: ${({ fontWeight }) => fontWeight};
  text-align: ${({ textAlign }) => textAlign};
  margin-bottom: 0;
  line-height: 120%;
  color: ${COLORS.DARK.Main};

  @media ${QUERIES.upToMobile} {
    font-size: ${({ fontSize }) => `${fontSize / 1.2}px`};
  }
`;

export function Title({ children, as = "h1", fontSize = 26, fontWeight = WEIGHT.bold, textAlign = "center" }) {
  return (
    <Heading as={as} fontSize={fontSize} fontWeight={fontWeight} textAlign={textAlign}>
      {children}
    </Heading>
  );
}

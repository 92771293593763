import React from "react";
import { useTranslation } from "react-i18next";

import * as Styled from "../style";

export function NoMapLocation() {
  const { t } = useTranslation();

  return (
    <Styled.LoadingWrapper>
      <span>{t("We were unable to find provided location.")}</span>
    </Styled.LoadingWrapper>
  );
}

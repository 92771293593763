import { createGlobalStyle } from "styled-components";

import { COLORS, FONTS, WEIGHT } from "configs/styles/common";

export const GlobalStyle = createGlobalStyle`
  :root {
    --toastify-color-info: #57b6e1;
    --toastify-color-error: #d12727;
    --toastify-color-success: #22c55e;
    --toastify-icon-color-info: var(--toastify-color-info);
    --toastify-icon-color-success: var(--toastify-color-success);
    --toastify-icon-color-error: var(--toastify-color-error);
  }

  html, body {
    min-height: 100%;
    font-family: ${FONTS.Main};
    font-weight: ${WEIGHT.regular};
  }


  body {
    color: ${COLORS.DARK.Main};
    background-color: #f8f7f6;
  }
  
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  ul[class],
  ol[class] {
    padding: 0;
  }

  * {
    margin: 0;
    
    @supports (scrollbar-width: auto) {
      scrollbar-width: thin;
      scrollbar-color: #d0cdcd #f6f5f5;
    }
  }

  ul {
    list-style: none;
  }

  img, picture, video, canvas, svg {
    max-width: 100%;
    display: block;
  }

  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  a {
    text-decoration: none;
    color: inherit;

    &:hover {
      text-decoration: none;
      color: inherit;
    }
  }

  p, h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, label, ul, ol, span {
    font-family: ${FONTS.Main};
    overflow-wrap: break-word;
    margin: 0;
  }

  #root {
    height: 100%;
    isolation: isolate;
  }

  .App {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  ::-webkit-scrollbar {
    width: 3px;
    background-color: #d0cdcd;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #d0cdcd;
  }

  ::-webkit-scrollbar-track {
    background-color: #f6f5f5;
  }

  /* *************GOOGLE MAPS API************** */

  a[href^="http://maps.google.com/maps"] {
    display: none !important;
  }

  a[href^="https://maps.google.com/maps"] {
    display: none !important;
  }

  .gmnoprint a,
  .gmnoprint span,
  .gm-style-cc {
    display: none;
  }

  .gmnoprint div {
    background: none !important;
  }

  body > iframe[style*="2147483647"] {
    display: none;
  }
`;

import React from "react";
import { useTranslation } from "react-i18next";

import { CHART_COLORS } from "services/constants";

import * as Styled from "./styles";

export default function RangeBar({
  conditionData,
  labelPoints,
  isSmall = false,
  isSingleSearch = false,
  percent = false,
  rangeInput = false,
}) {
  const { t } = useTranslation("attributes");

  const preparedLabels = labelPoints.map((label) => label.split("_").join(" "));
  const LegendItems = () =>
    preparedLabels.map((item, index) => (
      <Styled.LegendLabel key={`${item[index]}_${index}`}>
        <Styled.LegendCircle color={CHART_COLORS[index]} />
        <Styled.LabelText>{t(item)}</Styled.LabelText>
      </Styled.LegendLabel>
    ));
  const conditions = !percent ? ["low", "medium", "high", "very_high"] : ["0%", "25%", "50%", "75%", "100%"];
  let val;
  if (percent && !rangeInput)
    conditions.map((elem) => {
      if (conditionData >= parseFloat(elem)) val = elem;
    });

  if (percent && rangeInput) {
    val = conditions[conditionData];
  }

  const findVal = !percent ? conditionData : conditionData ? val : null;

  const index = labelPoints.findIndex((v) => v === findVal);

  return (
    <Styled.RangeBarWrapper isSmall={isSmall} isSingleSearch={isSingleSearch}>
      <Styled.RangePointWrap conditionData={index >= 0 ? conditions[index] : null} isSmall={isSmall} percent={percent}>
        <Styled.WarnText>{t("No Data")}</Styled.WarnText>
        <Styled.RangePoint>
          <Styled.PointCircle />
          <Styled.PointTriangle />
        </Styled.RangePoint>
      </Styled.RangePointWrap>

      <Styled.RangeBar percent={percent} />

      <Styled.Legend>
        <LegendItems />
      </Styled.Legend>
    </Styled.RangeBarWrapper>
  );
}

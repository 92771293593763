import styled, { keyframes } from "styled-components";

import { CHART_COLORS } from "services/constants";
import { COLORS, QUERIES } from "configs/styles/common";

function getAnimationName(data, figure) {
  let animationName;
  switch (data) {
    case "medium":
    case "25%":
      animationName = figure === "circle" ? changeCircleColorM : changeTriangleColorM;
      break;
    case "high":
    case "50%":
      animationName = figure === "circle" ? changeCircleColorH : changeTriangleColorH;
      break;
    case "very_high":
    case "75%":
      animationName = figure === "circle" ? changeCircleColorVH : changeTriangleColorVH;
      break;
    case "100%":
      animationName = figure === "circle" ? changeCircleColorVVH : changeTriangleColorVVH;
      break;
    default:
      animationName = null;
  }
  return animationName;
}

export const RangeBar = styled.div`
  width: 100%;
  height: 2px;
  border-radius: 5px;
  background: ${(props) =>
    !props.percent
      ? `linear-gradient(90deg, ${CHART_COLORS[0]} 10%, ${CHART_COLORS[1]} 30%, ${CHART_COLORS[2]} 60%, ${CHART_COLORS[3]} 100%)`
      : `linear-gradient(90deg, ${CHART_COLORS[0]} 0%, ${CHART_COLORS[1]} 20%, ${CHART_COLORS[2]} 45%, ${CHART_COLORS[3]} 70%, ${CHART_COLORS[4]} 90%)`};

  @media ${QUERIES.upToTablet} {
    width: 97%;
  }
`;

export const Legend = styled.div`
  display: flex;
  height: auto;
  justify-content: space-between;
  position: relative;
`;

export const LegendLabel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 46px;
`;

export const Circle = styled.span`
  background-color: ${(props) => props.color};
  border-radius: 50%;
`;

export const LabelText = styled.p`
  font-weight: 300;
  line-height: 138.8%;
  text-transform: capitalize;
  color: ${COLORS.DARK.Main};
`;

export const LegendCircle = styled(Circle)`
  margin-bottom: 2px;
`;

const rangeData = {
  low: { distance: "2%", colors: [CHART_COLORS[0]] },
  medium: { distance: "33.5%", colors: [CHART_COLORS[1]] },
  high: { distance: "65.5%", colors: [CHART_COLORS[1], CHART_COLORS[2]] },
  very_high: { distance: "96%", colors: [CHART_COLORS[1], CHART_COLORS[2], CHART_COLORS[3]] },
};

const rangeTreeOverhangData = {
  "0%": { distance: "2%", colors: [CHART_COLORS[0]] },
  "25%": { distance: "25.3%", colors: [CHART_COLORS[1]] },
  "50%": { distance: "49%", colors: [CHART_COLORS[1], CHART_COLORS[2]] },
  "75%": { distance: "72.7%", colors: [CHART_COLORS[1], CHART_COLORS[2], CHART_COLORS[3]] },
  "100%": { distance: "97%", colors: [CHART_COLORS[1], CHART_COLORS[2], CHART_COLORS[3], CHART_COLORS[4]] },
};

const movePoint = (percent, conditionData) => keyframes`
  from {
    transform: translate(0);
    left: 0;
  }
  to {
    left: ${percent ? rangeTreeOverhangData[conditionData]?.distance : rangeData[conditionData]?.distance};
    transform: ${
      percent
        ? `translate(-${rangeTreeOverhangData[conditionData]?.distance})`
        : `translate(-${rangeData[conditionData]?.distance})`
    };
  }
`;

const changeCircleColorM = keyframes`
  0% {
    background-color: ${CHART_COLORS[0]};
  }
  
  100% {
    background-color: ${CHART_COLORS[1]};
  }
`;

const changeCircleColorH = keyframes`
  0% {
    background-color: ${CHART_COLORS[0]};
  }
  
  50% {
    background-color: ${CHART_COLORS[1]};
  }

  100% {
    background-color: ${CHART_COLORS[2]};
  }
`;

const changeCircleColorVH = keyframes`
  0% {
    background-color: ${CHART_COLORS[0]};
  }
  
  33% {
    background-color: ${CHART_COLORS[1]};
  }

  66% {
    background-color: ${CHART_COLORS[2]};
  }

  100% {
    background-color: ${CHART_COLORS[3]};
  }
`;

const changeCircleColorVVH = keyframes`
  0% {
    background-color: ${CHART_COLORS[0]};
  }
  
  25% {
    background-color: ${CHART_COLORS[1]};
  }

  50% {
    background-color: ${CHART_COLORS[2]};
  }

  75% {
    background-color: ${CHART_COLORS[3]};
  }

  100% {
    background-color: ${CHART_COLORS[4]};
  }
`;

const changeTriangleColorM = keyframes`
  0% {
    border-color: ${CHART_COLORS[0]} transparent transparent transparent;
  }
  100% {
    border-color: ${CHART_COLORS[1]} transparent transparent transparent;
  }
`;

const changeTriangleColorH = keyframes`
  0% {
    border-color: ${CHART_COLORS[0]} transparent transparent transparent;
  }
  50% {
    border-color: ${CHART_COLORS[1]} transparent transparent transparent;
  }
  100% {
    border-color: ${CHART_COLORS[2]} transparent transparent transparent;
  }
`;

const changeTriangleColorVH = keyframes`
  0% {
    border-color: ${CHART_COLORS[0]} transparent transparent transparent;
  }
  33% {
    border-color: ${CHART_COLORS[1]} transparent transparent transparent;
  }
  66% {
    border-color: ${CHART_COLORS[2]} transparent transparent transparent;
  }
  100% {
    border-color: ${CHART_COLORS[3]} transparent transparent transparent;
  }
`;

const changeTriangleColorVVH = keyframes`
  0% {
    border-color: ${CHART_COLORS[0]} transparent transparent transparent;
  }
  25% {
    border-color: ${CHART_COLORS[1]} transparent transparent transparent;
  }
  50% {
    border-color: ${CHART_COLORS[2]} transparent transparent transparent;
  }
  75% {
    border-color: ${CHART_COLORS[3]} transparent transparent transparent;
  }
  100% {
    border-color: ${CHART_COLORS[4]} transparent transparent transparent;
  }
`;

export const RangePoint = styled.div`
  width: 18px;
  height: 27px;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  position: absolute;
`;

export const PointCircle = styled.span`
  width: 15px;
  height: 13px;
  border-radius: 50%;
  top: 8.5px;
  left: -2.7px;
  background-color: ${CHART_COLORS[0]};
  position: absolute;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
`;

export const PointTriangle = styled.span`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 6px 0;
  border-color: ${CHART_COLORS[0]} transparent transparent transparent;
  position: absolute;
  bottom: 0;
  left: -1.6px;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
`;

export const WarnText = styled.p`
  font-style: italic;
  margin-bottom: 5px;
`;

export const RangeBarWrapper = styled.div`
  & ${Circle} {
    width: 10px;
    height: 10px;
  }

  & ${RangeBar} {
    margin-bottom: 5px;
  }

  & ${Legend} {
    width: ${(props) => (props.isSingleSearch ? "106%" : "103%")};
    left: ${(props) => (props.isSmall ? "-12px" : "-9px")};

    @media screen and (max-width: 1780px) {
      width: ${(props) => (props.isSingleSearch ? "109%" : "103%")};
      padding-left: 0;
    }

    @media screen and (max-width: 1270px) {
      width: ${(props) => (props.isSingleSearch ? "112%" : "105%")};
    }

    @media ${QUERIES.upToTablet} {
      width: ${(props) => (props.isSingleSearch ? "112%" : "102%")};
    }
  }

  & ${LabelText} {
    font-size: ${(props) => (props.isSmall ? "12px" : "1rem")};
  }

  & ${WarnText} {
    @media screen and (max-width: 1780px) {
      line-height: ${(props) => props.isSingleSearch && "initial"};
    }
  }

  @media screen and (max-width: 1270px) {
    align-self: center;
    ${(props) => props.isSingleSearch && "width: 94%"};
  }
`;

export const RangePointWrap = styled.div`
  margin-bottom: 2px;
  width: 100%;
  height: 27px;
  position: relative;

  & ${WarnText} {
    display: ${(props) => (props.conditionData ? "none" : "block")};
    font-size: 0.9rem;
    position: static;

    @media screen and (min-width: 1780px) {
      bottom: ${(props) => (props.isSmall ? "16px" : "0")};
    }
  }

  & ${RangePoint} {
    animation-name: ${(props) => movePoint(props.percent, props.conditionData)};
    display: ${(props) => (props.conditionData ? "block" : "none")};
  }

  & ${PointCircle} {
    animation-name: ${(props) => {
      return getAnimationName(props.conditionData, "circle");
    }};
  }
  & ${PointTriangle} {
    animation-name: ${(props) => {
      return getAnimationName(props.conditionData, "triangle");
    }};
  }
`;

import React, { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import { v4 as uuid } from "uuid";

import styles from "./MultipleFinite.module.css";

export default function MultipleFinite({ inputText, labelText, type, options, value }) {
  const id = uuid();

  const [selected, setSelected] = useState([]);
  const selectOptions = options?.map((option) => {
    return { key: uuid(), text: option, value: option };
  });

  useEffect(() => {
    if (!value) setSelected([]);
  }, [value]);

  const onChange = (id, data) => {
    setSelected(data.value);
  };

  return (
    <>
      <label htmlFor={id}>{labelText}</label>
      <div className={styles.selectWrapper}>
        <Dropdown
          data-formtype={type}
          id={id}
          placeholder={inputText}
          fluid
          multiple
          selection
          clearable
          options={selectOptions}
          className={styles.select + " multipleFiniteInputs"}
          onChange={onChange}
          value={selected}
          data-selected={selected}
        />
      </div>
    </>
  );
}

import React from "react";
import { useTranslation } from "react-i18next";

import * as Styled from "../style";

export function MapLoader() {
  const { t } = useTranslation();

  return (
    <Styled.LoadingWrapper>
      <span>{t("Map is loading...")}</span>
    </Styled.LoadingWrapper>
  );
}

import React from "react";
import styled from "styled-components/macro";

export function TabContent({ id, activeTab, children, activeButton, index }) {
  const paginationCheck = activeButton === undefined || index === undefined ? true : activeButton === index;
  return activeTab === id && paginationCheck ? <Content data-testid="tab-content">{children}</Content> : null;
}

const Content = styled.div`
  height: 100%;
  width: 100%;
`;

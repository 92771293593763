import React, { lazy } from "react";
import { Switch, Redirect } from "react-router-dom";

import AdminRoute from "../services/helpers/routing/AdminRoute";
import ManageBills from "./ManageBills";

const AdminPage = lazy(() => import(/* webpackChunkName: "AdminPage" */ "./AdminPage"));
const ManagePlans = lazy(() => import(/* webpackChunkName: "ManagePlans" */ "./ManagePlans"));
const ManageUsers = lazy(() => import(/* webpackChunkName: "ManageUsers" */ "./ManageUsers"));
const ManageProjects = lazy(() => import(/* webpackChunkName: "ManageProjects" */ "./ManageProjects"));
const ManageOrders = lazy(() => import(/* webpackChunkName: "ManageOrders" */ "./ManageOrders"));
const LoginActivity = lazy(() => import(/* webpackChunkName: "LoginActivity" */ "./LoginActivity"));
const ManageCoupons = lazy(() => import(/* webpackChunkName: "ManageCoupons" */ "./ManageCoupons"));
const ManageContactUs = lazy(() => import(/* webpackChunkName: "ManageContactUs" */ "./ManageContactUs"));
const ManageFeedback = lazy(() => import(/* webpackChunkName: "ManageContactUs" */ "./ManageFeedback"));

export default function AdminSwitch() {
  return (
    <>
      <Switch>
        <AdminRoute path="/admin" exact component={AdminPage} />

        <AdminRoute path="/admin/manage-plans" exact component={ManagePlans} />

        <AdminRoute path="/admin/manage-users" exact component={ManageUsers} />

        <AdminRoute path="/admin/manage-coupons" exact component={ManageCoupons} />

        <AdminRoute path="/admin/manage-projects" exact component={ManageProjects} />

        <AdminRoute path="/admin/contact-us" exact component={ManageContactUs} />

        <AdminRoute path="/admin/feedback" exact component={ManageFeedback} />

        <AdminRoute path="/admin/analytics/login-activity" exact component={LoginActivity} />

        <AdminRoute path="/admin/manage-orders" exact component={ManageOrders} />

        <AdminRoute path="/admin/bills" exact component={ManageBills} />

        <Redirect to="/admin" />
      </Switch>
    </>
  );
}

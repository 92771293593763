import styled, { keyframes } from "styled-components";
import Popup from "reactjs-popup";

import { BORDER_RADIUS, COLORS, iconButtonStyle, QUERIES } from "configs/styles/common";

export const scaleInTop = keyframes`
  0% {
    transform: scale(0);
    transform-origin: 50% 0%;
    opacity: 1;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 0%;
    opacity: 1;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
`;

export const StyledPopup = styled(Popup)`
  &-overlay {
    backdrop-filter: blur(4px);
    animation: ${fadeIn} 400ms cubic-bezier(0.14, 0.78, 0.36, 1);
    background-color: rgb(255 255 255 / 80%);
    padding: 60px 15px 20px;
  }

  &-content {
    width: ${({ modalWidth }) => (modalWidth ? `max(${modalWidth}, 500px)` : "max(50%, 500px)")};
    padding: 24px 36px;
    display: grid;
    place-items: center;
    box-shadow: rgb(0 0 0 / 0%) 0 0 0 0, rgb(0 0 0 / 0%) 0 0 0 0, rgb(0 0 0 / 10%) 0 10px 15px -3px,
      rgb(0 0 0 / 10%) 0 4px 6px -4px;
    border-radius: ${BORDER_RADIUS.MODAL};
    background-color: ${COLORS.LIGHT.White};
    border: 1px solid rgb(228 228 231);

    @media (prefers-reduced-motion: no-preference) {
      animation: ${scaleInTop} 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }

    @media ${QUERIES.upToTablet} {
      width: ${({ modalWidth }) => (modalWidth ? `max(${modalWidth}, 450px)` : "max(60%, 450px)")};
    }

    @media ${QUERIES.upToMobile} {
      width: 100%;
    }
  }
`;

export const CloseButton = styled.button`
  ${iconButtonStyle};
  position: absolute;
  top: -50px;
  right: 0;
`;

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgb(255 255 255 / 80%);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

import React from "react";
import ToolTip from "rc-tooltip";
import { useTranslation } from "react-i18next";

import "rc-tooltip/assets/bootstrap.css";
import "./index.css";

export function Tooltip({ children, content, placement = "top", isEmpty = false }) {
  const { i18n } = useTranslation();
  const className = i18n.language === "ja" ? "jp" : "";

  return (
    <ToolTip placement={placement} overlay={content} overlayClassName={`${className} ${isEmpty && "empty"}`}>
      {children}
    </ToolTip>
  );
}

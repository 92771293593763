import React from "react";
import { useTranslation } from "react-i18next";

import * as Styled from "../style";

export function MapError() {
  const { t } = useTranslation();

  return (
    <Styled.ErrorWrapper>
      <span>{t("Map cannot be loaded right now, sorry.")}</span>
    </Styled.ErrorWrapper>
  );
}

import axios from "axios";

const basicConfig = {
  apiBase: "/api",
  isRefreshing: false,

  setupInterceptors() {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        if (error.response.status === 401) {
          if (!this.isRefreshing) {
            this.isRefreshing = true;
            await this.refreshToken();

            return axios(error.config);
          }
          while (this.isRefreshing) {
            await new Promise((resolve) => setTimeout(resolve, 100));
          }

          return axios(error.config);
        }
        return Promise.reject(error);
      }
    );
  },

  async createRequest(endpoint, ...rest) {
    const options = this.createOptions(...rest);

    return axios(this.apiBase + endpoint, options);
  },

  createOptions(method, headers, data, isFormData = false, params, isBlob) {
    const options = {};
    if (headers) options.headers = headers;
    if (data && !isFormData) options.data = JSON.stringify(data);
    if (data && isFormData) options.data = data;
    if (params) options.params = params;
    options.method = method;
    if (isBlob) options.responseType = "blob";
    return options;
  },

  refreshToken() {
    return axios
      .post(
        this.apiBase + "/v1/auth/refresh-token",
        {},
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then(() => {
        this.isRefreshing = false;
      });
  },
};

basicConfig.setupInterceptors();

export default basicConfig;

import React from "react";
import ReactDOM from "react-dom";

import * as Styled from "./styles";

function WelcomeOverlay() {
  return (
    <Styled.Overlay>
      <Styled.Logo />
    </Styled.Overlay>
  );
}

export default function Overlay() {
  ReactDOM.render(<WelcomeOverlay />, document.getElementById("overlay-root"));
  setTimeout(() => {
    ReactDOM.unmountComponentAtNode(document.getElementById("overlay-root"));
  }, 4000);
}

import React, { useState } from "react";

import styles from "./SidebarForm.module.css";
import Text from "../Filters/Text";
import Arrange from "../Filters/Arrange";
import Button from "../Filters/Button";
import { getTextFields, getArrangeFields, clearInputs, getMultipleFiniteFields } from "../helpers/sideBarMethods";
import MultipleFinite from "../Filters/MultipleFinite";

export default function SidebarForm({ apply, filtersValues, setFilters, submitFilter }) {
  const [value, setValue] = useState(true);

  const clear = () => {
    apply(false);
    clearInputs(setValue);
    window.localStorage.removeItem("bill-recordsfilters");
    window.localStorage.removeItem("bill-recordsfiltersValues");
    setFilters({});
    submitFilter({});
  };

  const submit = () => {
    apply(false);
    const text = getTextFields();
    const arrange = getArrangeFields();
    const multipleFinite = getMultipleFiniteFields();

    const filterValues = {
      ...text,
      ...arrange,
      ...multipleFinite,
    };

    window.localStorage.setItem("bill-recordsfiltersValues", JSON.stringify(filterValues));
    setFilters(filterValues);

    const [minPurchaseDate, maxPurchaseDate] = arrange["purchase date"]
      ? arrange["purchase date"].match(/(?!from)( \w+-\w+-+\w+)/g)
      : [];

    const filters = {
      purchase_date: {
        min: minPurchaseDate ? minPurchaseDate.trim() : null,
        max: maxPurchaseDate ? maxPurchaseDate.trim() : null,
      },
      customer_email: text.email,
      customer_first_name: text["first name"],
      customer_last_name: text["last name"],
      status: multipleFinite.status,
      subscription_type: multipleFinite["subscription type"],
      plan_name: multipleFinite["plan name"],
    };

    submitFilter(filters);
    window.localStorage.setItem("bill-recordsfilters", JSON.stringify(filters));
  };

  return (
    <aside className={styles.SidebarForm}>
      <h2>Select filters</h2>
      <Text type="email" labelText="Customer email:" inputText="e.g. some@some.com" />
      <Text type="first name" labelText="Customer first name:" inputText="e.g. John" />
      <Text type="last name" labelText="Customer last name:" inputText="e.g. Black" />
      <Arrange type="purchase date" isDate labelText="Purchase date:" inputTextFrom="From" inputTextTo="To" />
      <MultipleFinite
        value={value}
        type="status"
        inputText="Select a status"
        labelText="Select a status"
        options={filtersValues?.status || []}
      />
      <MultipleFinite
        value={value}
        type="plan name"
        inputText="Select a plan name"
        labelText="Select a plan name"
        options={filtersValues?.plan_name || []}
      />
      <MultipleFinite
        value={value}
        type="subscription type"
        inputText="Select a subscription type"
        labelText="Select a subscription type"
        options={filtersValues?.subscription_type || []}
      />
      <Button onClick={clear} buttonText="Clear all filters" />
      <Button onClick={submit} buttonText="Apply" />
    </aside>
  );
}

import basicConfig from "./basicConfig";

const _ = undefined;

const userAPI = {
  updateUserInfo(payload) {
    return basicConfig
      .createRequest("/customer", "PATCH", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },

  updateUserPassword(payload) {
    return basicConfig
      .createRequest("/customer/password", "POST", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },

  updateUserPicture(payload) {
    return basicConfig
      .createRequest("/customer/profile-picture", "POST", { "content-type": "application/json" }, payload, true)
      .then((res) => res.data);
  },

  createCustomer(email, name, phone, source) {
    const data = {
      email,
      name,
      phone,
      source,
    };
    return basicConfig
      .createRequest("/customer/create_customer", "POST", { "content-type": "application/json" }, data)
      .then((res) => res.data);
  },

  getApiKey(renew) {
    const data = {
      renew,
    };
    return basicConfig
      .createRequest("/customer/generate-key", "POST", { "content-type": "application/json" }, data)
      .then((res) => res.data);
  },

  createSubscription(planId) {
    const data = {
      planId,
    };
    return basicConfig
      .createRequest("/customer/stripe_subscription", "POST", { "content-type": "application/json" }, data)
      .then((res) => res.data);
  },

  getUser() {
    return basicConfig.createRequest("/customer", "GET").then((res) => res.data);
  },

  getUserBasicInfo() {
    return basicConfig.createRequest("/v1/customer/profile/basic", "GET").then((res) => res.data);
  },

  getUserPicture() {
    return basicConfig.createRequest("/customer/profile-picture", "GET").then((res) => res.data);
  },

  getUsers() {
    return basicConfig.createRequest("/customer/list", "GET").then((res) => res.data);
  },

  updateUser(id, payload) {
    return basicConfig
      .createRequest(`/customer/update/one/${id}`, "PATCH", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },

  addPaymentMethod(pfToken) {
    const data = {
      pf_token: pfToken,
    };

    return basicConfig
      .createRequest("/payment-method-card", "POST", { "content-type": "application/json" }, data)
      .then((res) => {
        return res.data;
      });
  },

  getPaymentMethod() {
    return basicConfig.createRequest("/payment-method-card", "GET").then((res) => {
      return res.data;
    });
  },

  getNotificationsAvailability() {
    return basicConfig.createRequest("/notifications", "GET").then((res) => {
      return res.data;
    });
  },

  deletePaymentMethod(id) {
    const params = {
      card_id: id,
    };

    return basicConfig.createRequest(
      "/payment-method-card",
      "DELETE",
      { "content-type": "application/json" },
      _,
      _,
      params
    );
  },

  getNotifications() {
    return basicConfig.createRequest("/notifications", "POST").then((res) => {
      return res.data;
    });
  },

  getCurrentPlan() {
    return basicConfig.createRequest("/customer/my-plan", "GET").then((res) => {
      return res.data;
    });
  },

  assignPlan(payload) {
    return basicConfig
      .createRequest("/customer/assign-plan", "POST", { "content-type": "application/json" }, payload)
      .then((res) => {
        return res.data;
      });
  },

  getTransactionHistory(page_num, page_size) {
    const params = {
      page_num,
      page_size,
    };

    return basicConfig
      .createRequest("/bills/", "GET", { "content-type": "application/json" }, _, _, params)
      .then((res) => {
        return res.data;
      });
  },

  adminGetUsers(params) {
    return basicConfig.createRequest("/v1/admin/customers", "GET", _, _, _, params).then((res) => res.data);
  },

  adminFilterUsers(payload) {
    return basicConfig
      .createRequest("/v1/admin/customers/filter", "POST", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },

  adminCreateUser(data) {
    return basicConfig
      .createRequest("/v1/admin/customers", "POST", { "content-type": "application/json" }, data)
      .then((res) => res.data);
  },

  adminUpdateUser(id, data) {
    return basicConfig
      .createRequest(`/v1/admin/customers/${id}`, "PATCH", { "content-type": "application/json" }, data)
      .then((res) => res.data);
  },

  adminGetAllContactUs(params) {
    return basicConfig.createRequest("/v1/admin/contact-us", "GET", _, _, _, params).then((res) => res.data);
  },

  adminFilterContactUs(payload) {
    return basicConfig
      .createRequest("/v1/admin/contact-us/filter", "POST", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },

  adminGetAllFeedback(params) {
    return basicConfig.createRequest("/v1/admin/feedback", "GET", _, _, _, params).then((res) => res.data);
  },

  adminFilterFeedback(payload) {
    return basicConfig
      .createRequest("/v1/admin/feedback/filter", "POST", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },

  changePassword(current_password, new_password) {
    const payload = {
      current_password,
      new_password,
    };

    return basicConfig
      .createRequest("/customer/password", "POST", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },

  resetPassword(email) {
    return basicConfig
      .createRequest("/v1/auth/send-reset-password-email", "POST", { "content-type": "application/json" }, { email })
      .then((res) => res.data);
  },

  validateResetPasswordToken(token) {
    return basicConfig
      .createRequest(
        "/v1/auth/validate-reset-password-token",
        "POST",
        { "content-type": "application/json" },
        { token }
      )
      .then((res) => res.data);
  },

  resetPasswordWithToken(new_password, token) {
    return basicConfig
      .createRequest(
        "/v1/auth/reset-password-with-token",
        "POST",
        { "content-type": "application/json" },
        { new_password, token }
      )
      .then((res) => res.data);
  },

  blockUser(id) {
    return basicConfig
      .createRequest(`/v1/admin/customers/${id}`, "PATCH", { "content-type": "application/json" }, { is_blocked: true })
      .then((res) => res.data);
  },

  unblockUser(id) {
    return basicConfig
      .createRequest(
        `/v1/admin/customers/${id}`,
        "PATCH",
        { "content-type": "application/json" },
        { is_blocked: false }
      )
      .then((res) => res.data);
  },

  resetWrongPasswordBlock(id) {
    return basicConfig
      .createRequest(
        `/v1/admin/customers/${id}`,
        "PATCH",
        { "content-type": "application/json" },
        { reset_wrong_password_block: true }
      )
      .then((res) => res.data);
  },

  sendFeedback(payload) {
    return basicConfig
      .createRequest("/v1/feedback", "POST", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },

  sendEmailVerification() {
    return basicConfig
      .createRequest("/v1/auth/start-email-verification-process", "POST", {
        "content-type": "application/json",
      })
      .then((res) => res.data);
  },

  processEmailVerificationToken(token, password) {
    return basicConfig
      .createRequest(
        "/v1/auth/process-email-verification-token",
        "POST",
        { "content-type": "application/json" },
        { token, password }
      )
      .then((res) => res.data);
  },

  exportCustomers() {
    return basicConfig
      .createRequest(
        "/v1/admin/customers/export",
        "GET",
        {
          "content-type": "text/csv",
        },
        _,
        _,
        _,
        true
      )
      .then((res) => res.data);
  },

  unsubscribeNewsLetter({ email, reason = "" }) {
    return basicConfig
      .createRequest("/v1/customer/unsubscribe", "POST", { "content-type": "application/json" }, { email, reason })
      .then((res) => res.data);
  },

  resubscribeNewsLetter({ email }) {
    return basicConfig
      .createRequest("/v1/customer/resubscribe", "POST", { "content-type": "application/json" }, { email })
      .then((res) => res.data);
  },
};

export default userAPI;

import React from "react";
import { Link } from "react-router-dom";

import { Header, Footer, Button } from "components/presenters";
import { ReactComponent as CrossSVG } from "assets/icons/modals/cross_icon.svg";

import * as Styled from "./NotFoundPage.style";

export default function NotFoundPage() {
  return (
    <>
      <Header />

      <Styled.Wrapper>
        <Styled.MessageContainer>
          <Styled.Error>
            <CrossSVG />
          </Styled.Error>

          <Styled.ErrorText>404</Styled.ErrorText>

          <Styled.Text>
            The page you are looking for <span>does not exist.</span>
          </Styled.Text>

          <Link to="/home" style={{ width: "100%" }}>
            <Button style={{ maxWidth: "260px", height: "46px", color: "#23242a", margin: "auto" }}>Back</Button>
          </Link>
        </Styled.MessageContainer>
      </Styled.Wrapper>

      <Footer />
    </>
  );
}

import { BOX_SHADOW, QUERIES } from "configs/styles/common";
import { S3 } from "services/constants";
const LangIcon = S3 + "assets/shared/icons/language-icon.svg";

const media = `@media ${QUERIES.upToTablet}`;

export const langStyle = {
  container: (provided) => ({
    ...provided,
    width: "82px",
  }),
  placeholder: (provided) => ({
    ...provided,
    border: "none",
  }),
  indicatorSeparator: () => ({
    position: "absolute",
    cursor: "pointer",
    backgroundImage: `url(${LangIcon})`,
    backgroundRepeat: "no-repeat",
    width: "30px",
    height: "28px",
    left: "10px",
    [media]: {
      backgroundSize: "33px",
      backgroundPosition: "center center",
      height: "35px",
      width: "33px",
    },
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: "8px",
    boxShadow: BOX_SHADOW.REGULAR,
    borderRadius: "6px",
  }),
  control: (provided) => ({
    ...provided,
    border: "none",
    borderRadius: "8px",
    boxShadow: "0 4px 14px rgba(0, 0, 0, 0.13)",
    [media]: {
      height: "65px",
      boxShadow: "none",
    },
  }),
};

import React from "react";
import styled from "styled-components";

import { S3 } from "services/constants/API";
import { Button as StyledButton, COLORS, QUERIES } from "configs/styles/common";

const check = S3 + "assets/check1.svg";

const TickCircle = styled.div`
  margin: 0 auto;
  margin-bottom: 2.3333rem;
  width: 6.4444rem;
  height: 6.4444rem;
  background-color: ${COLORS.ACCENT};
  background-image: url(${check});
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
`;

const Title = styled.h3`
  font-family: Bitter;
  font-weight: bold;
  font-size: 3.3333rem;
  line-height: 3.3333rem;
  text-align: center;
  color: ${COLORS.DARK.Main};
  margin-bottom: 2.0556rem;
`;

const Text = styled.p`
  font-size: 1.3333rem;
  line-height: 132.3%;
  text-align: center;
  color: ${COLORS.DARK.Main};
  margin-bottom: 4.3889rem;
`;

const Button = styled(StyledButton)`
  width: 100%;
  height: 3.6667rem;
  border-radius: 1.3889rem;

  @media ${QUERIES.upToTablet} {
    height: 5.6667rem;
  }
`;

export default function SuccessMessage({ onBackButtonClick }) {
  return (
    <>
      <TickCircle />
      <Title>Success!</Title>
      <Text>
        Please verify your account through the email sent.
        <br />
        If you can&apos;t find it, check your spam folder.
      </Text>
      <Button onClick={onBackButtonClick}>Back</Button>
    </>
  );
}

import basicConfig from "./basicConfig";
const _ = undefined;

const singleSearchAPI = {
  initiateSingleSearch(payload) {
    return basicConfig
      .createRequest("/v1/single-search", "GET", { "content-type": "application/json" }, _, _, payload)
      .then((res) => res.data);
  },
  getAllAddresses(payload) {
    return basicConfig
      .createRequest("/v1/all-addresses", "GET", { "content-type": "application/json" }, _, _, payload)
      .then((res) => res.data);
  },
  getSingleSearchHistory() {
    return basicConfig.createRequest("/v1/single-search/history?page_num=1&page_size=1", "GET").then((res) => res.data);
  },
};

export default singleSearchAPI;

import styled, { css } from "styled-components";

import { baseButtonStyle, BORDER_RADIUS, COLORS, WEIGHT, BOX_SHADOW } from "configs/styles/common";

export const Button = styled.button`
  ${baseButtonStyle};
  font-weight: ${WEIGHT.medium};
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  box-shadow: ${BOX_SHADOW.LIGHT};
  border-radius: ${BORDER_RADIUS.S};
  letter-spacing: ${({ lang }) => lang !== "ja" && "0.3px"};
  width: 100%;
  padding: 5px 10px;
  height: 45px;
  transition: all 300ms ease-out;

  &:focus {
    outline: 1.5px solid ${COLORS.DARK.Main};
  }

  &:disabled {
    background-color: ${COLORS.GREY.Main};
    border: 1px solid ${COLORS.GREY.Main};
    cursor: default;

    &:hover {
      transform: none;
      background-color: ${COLORS.GREY.Main};
      color: ${COLORS.DARK.Main};
    }
  }

  ${({ variant }) =>
    variant === "accent" &&
    css`
      background-color: ${COLORS.ACCENT};
      color: ${COLORS.DARK.Main};
      border: 1px solid ${COLORS.ACCENT};

      &:hover {
        color: ${COLORS.LIGHT.White};
        border-color: #ffffff7a;
        background-color: #0f1523;
        transform: scale(1.05);
      }
    `}

  ${({ variant }) =>
    variant === "contained" &&
    css`
      background-color: ${COLORS.DARK.Main};
      color: ${COLORS.LIGHT.White};
      position: relative;
      border: 1px solid ${COLORS.DARK.Main};
      z-index: 1;
      overflow: hidden;
      letter-spacing: 0.5px;

      &:hover {
        --shadow-color: 0deg 0% 0%;

        color: ${COLORS.DARK.Main};
        border-color: ${COLORS.ACCENT};
        background-color: ${COLORS.ACCENT};
      }
    `}


  ${({ variant }) =>
    variant === "outline" &&
    css`
      background-color: ${COLORS.LIGHT.White};
      box-shadow: ${BOX_SHADOW.LIGHT};
      border: 2px solid ${COLORS.LIGHT.Slate};
      color: ${COLORS.DARK.Main};

      &:hover {
        background-color: ${COLORS.LIGHT.Slate};
      }
    `}


  ${({ variant }) =>
    variant === "ghost" &&
    css`
      background-color: transparent;
      color: ${COLORS.DARK.Main};
      box-shadow: none;
      font-weight: 400;

      &:focus {
        outline: none;
      }
    `}
`;

import styled from "styled-components/macro";
import { css } from "styled-components";

import { QUERIES, COLORS, WEIGHT } from "configs/styles/common";

export const TabNavItem = styled.li`
  list-style-type: none;
  min-width: ${({ minWidth }) => minWidth};
  height: 100%;
  font-size: 16px;
  line-height: 22px;
  position: relative;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex: 1 1 50%;
  color: ${COLORS.DARK.Main};

  &:not(:first-of-type)::after {
    content: "";
    position: absolute;
    height: 14px;
    width: 1px;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    background-color: #cbc8c8;
  }

  &:hover {
    color: ${COLORS.DARK.Secondary};
    box-shadow: none;
  }

  @media ${QUERIES.upToMobile} {
    font-size: 13px;
    min-width: 40px;
  }

  ${(props) =>
    props.active &&
    css`
      position: relative;
      font-weight: ${WEIGHT.medium};

      &::before {
        content: "";
        position: absolute;
        background-color: ${COLORS.ACCENT};
        height: 0.29rem;
        width: 39%;
        bottom: 0;
        left: 31%;
        border-radius: 30px 30px 0 0;
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
      opacity: 0.5;

      &:hover {
        color: ${COLORS.DARK.Main};
      }
    `}
`;

import React from "react";
import ReactDOM from "react-dom";

import App from "./app/App";

import "configs/i18n/i18n.js";

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<App />, rootElement);
} else {
  ReactDOM.render(<App />, rootElement);
}

import React, { memo } from "react";
import { Marker } from "@react-google-maps/api";

import MarkerSVG from "assets/icons/marker.svg";

function CurrentLocationMarker({ position, markerURL = MarkerSVG, ...rest }) {
  return <Marker position={position} icon={{ url: markerURL }} {...rest} />;
}

export default memo(CurrentLocationMarker);

import React, { memo, useCallback, useState } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";

import { MAP_LOADER } from "services/constants/MAP";
import { MAP_TYPE, useMap } from "app/providers/business/MapProvider";

import { WHITE_MAP_THEME } from "./services/Theme";
import { MapTypeControl } from "./components";
import * as Styled from "./style";

function CustomGoogleMap({
  position,
  isLoading,
  children,
  mapContainerStyle,
  zoom,
  bounds,
  streetViewControl,
  fullscreenControl,
  zoomControl,
  id,
  extraOptions = {},
  typeControl = true,
}) {
  const { t } = useTranslation();
  const { activeMapType } = useMap();
  const { isLoaded, loadError } = useJsApiLoader(MAP_LOADER);

  const [map, setMap] = useState(null);

  const onLoad = useCallback(
    function onLoad(mapInstance) {
      setMap(mapInstance);
      const whiteMapType = new window.google.maps.StyledMapType(WHITE_MAP_THEME, { name: "White" });
      mapInstance.mapTypes.set("white", whiteMapType);
      mapInstance.setMapTypeId(!typeControl ? MAP_TYPE.SATELLITE : activeMapType);

      if (bounds) {
        mapInstance.fitBounds(bounds);
      }

      if (typeControl) {
        const typeControl = document.getElementById("map-type-control");
        mapInstance.controls[window.google.maps.ControlPosition.BOTTOM_RIGHT].push(typeControl);
      }
    },
    [activeMapType, bounds, typeControl]
  );

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  if (!isLoaded || isLoading) {
    return null;
  }

  if (loadError) {
    return (
      <Styled.Empty>
        <span>{t("Map cannot be loaded right now, sorry.")}</span>
      </Styled.Empty>
    );
  }

  if (!position.lat || !position.lng) {
    return (
      <Styled.Empty>
        <span>{t("We were unable to find provided location.")}</span>
      </Styled.Empty>
    );
  }

  return (
    <Styled.CustomGoogleMapWrapper>
      <GoogleMap
        id={id}
        onLoad={onLoad}
        onUnmount={onUnmount}
        mapContainerStyle={mapContainerStyle}
        center={position}
        zoom={zoom}
        options={{
          mapTypeControl: false,
          zoomControl,
          streetViewControl,
          fullscreenControl,
          minZoom: 3,
          mapTypeControlOptions: {
            mapTypeIds: ["roadmap", "satellite", "white"],
            position: window.google.maps.ControlPosition.BOTTOM_RIGHT,
          },
          ...extraOptions,
        }}
      >
        {children}
        <Styled.TypeControl id="map-type-control" style={{ display: !typeControl && "none" }}>
          <MapTypeControl map={map} />
        </Styled.TypeControl>
      </GoogleMap>
    </Styled.CustomGoogleMapWrapper>
  );
}

export default memo(CustomGoogleMap);

import React from "react";
import { useTranslation } from "react-i18next";
import isAlpha from "validator/es/lib/isAlpha";
import isEmail from "validator/es/lib/isEmail";
import isNumeric from "validator/es/lib/isNumeric";
import isStrongPassword from "validator/es/lib/isStrongPassword";

function ErrorText({ children, className }) {
  const { t } = useTranslation();

  return <p className={`form-error ${className ? className : ""}`}>{t(children)}</p>;
}

export const required = (value) => {
  if (!value?.toString().trim().length) {
    return <ErrorText>This field is required.</ErrorText>;
  }
};

export const email = (value) => {
  if (value && !isEmail(value)) {
    return <ErrorText>The email you entered is invalid. Please provide a valid email address.</ErrorText>;
  }
};

export const isAlphabet = (value) => {
  if (!isAlpha(value)) {
    return <ErrorText>Please input alphabet only.</ErrorText>;
  }
};

export const isNumber = (value) => {
  if (!isNumeric(value)) {
    return <ErrorText>Please input number only.</ErrorText>;
  }
};

export const isTermChecked = (value) => {
  if (!value || value === "false") {
    return <p className="form-error">Please check Terms & Condition first.</p>;
  }
};

export const strongPassword = (value) => {
  if (!isStrongPassword(value, { minUppercase: 0, minSymbols: 0 })) {
    return (
      <ErrorText className="password">
        Please make sure that the password contains at least 8 characters, including a number.
      </ErrorText>
    );
  }
};

export const lt = (value, props) => {
  if (value.toString().trim().length > props.maxLength) {
    return <ErrorText>The value exceeded {props.maxLength} symbols.</ErrorText>;
  }
};

export const isEqual = (value, props, components) => {
  if (value !== components["email"][0].value) {
    // eslint-disable-next-line react/no-unescaped-entities
    return <ErrorText>This field doesn't match.</ErrorText>;
  }
};

export const isPasswordEqual = (value, props, components) => {
  if (components["newPassword"][0].value !== components["confirmedPassword"][0].value) {
    return (
      <ErrorText>
        The confirmed password does not match the original password. Please ensure both passwords are the same.
      </ErrorText>
    );
  }
};

export const isProjectNameUnique = (value, props) => {
  if (!props.isUnique) {
    return <ErrorText>This name is already in use</ErrorText>;
  }
};

import React, { useState, useRef } from "react";
import { useAlert } from "react-alert";
import isEmail from "validator/lib/isEmail";

import API from "api/apiClient";
import { ValidationInput } from "configs/styles/common";

import SuccessMessage from "./SuccessMessage";
import ContactUsMessage from "./ContactUsMessage";
import * as Styled from "../styles";

export default function NewAccount({ onBackButtonClick }) {
  const form = useRef();
  const alert = useAlert();
  const [isSuccessMessageShown, setIsSuccessMessageShown] = useState(false);
  const [isContactUsMessageShown, setIsContactUsMessageShown] = useState(false);
  const [isRevealPassword, setIsRevealPassword] = useState({
    password: false,
    confirm_password: false,
  });
  const [error, setError] = useState("");
  const initialStateData = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
  };
  const initialChangedData = {
    first_name: false,
    last_name: false,
    email: false,
    password: false,
    confirm_password: false,
  };

  const [isInputChanged, setIsInputChanged] = useState(initialChangedData);
  const [state, setState] = useState(initialStateData);

  const onInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setIsInputChanged((prevState) => ({
      ...prevState,
      [name]: true,
    }));
  };

  const onSubmitButtonClick = (event) => {
    event.preventDefault();
    form.current.validateAll();
    const { first_name, last_name, email, password, confirm_password } = state;
    const isEmailValid = isEmail(email);
    const isRequirementsFulfilled =
      first_name.length && last_name.length && isEmailValid && password.length && confirm_password.length;

    if (!isRequirementsFulfilled) {
      setError("* Please, enter your full name with valid work email and confirmed password");
      return;
    } else if (!isEmailValid && email.length) {
      setError("* Please, enter valid email or nothing");
      return;
    } else if (!password.length) {
      setError("* Please, enter password");
      return;
    } else if (!confirm_password.length) {
      setError("* Please, confirm password");
      return;
    } else if (password.length && confirm_password.length && password !== confirm_password) {
      setError("* Passwords, don`t match");
      return;
    }

    setError("");

    const payload = {
      email: state.email,
      first_name: state.first_name,
      last_name: state.last_name,
      password: state.password,
    };

    setData(payload);
  };

  const setData = (data) => {
    API.register(data)
      .then((data) => {
        if (data.status === 200) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({ event: "form_sent" });

          setIsSuccessMessageShown(true);

          setState(initialStateData);
          setIsInputChanged(initialChangedData);
        }
      })
      .catch((error) => {
        console.error(error);
        alert.error(error.response.data.msg);
      });
  };

  const togglePassword = (value) => {
    setIsRevealPassword((prevState) => ({
      ...prevState,
      [value]: !isRevealPassword[value],
    }));
  };

  return (
    <Styled.LeftSide>
      {isContactUsMessageShown || isSuccessMessageShown ? (
        isContactUsMessageShown ? (
          <ContactUsMessage onBackButtonClick={() => setIsContactUsMessageShown(false)} />
        ) : (
          <SuccessMessage onBackButtonClick={() => setIsSuccessMessageShown(false)} />
        )
      ) : (
        <>
          <Styled.Title>New account?</Styled.Title>

          <Styled.WelcomeMessage>
            Welcome to the new era <br />
            of property intelligence!
          </Styled.WelcomeMessage>

          <Styled.FormTitle marginBottom="1.1111rem">Please, give us a little insight</Styled.FormTitle>
          <Styled.StyledForm ref={form}>
            <ValidationInput
              type="text"
              name="first_name"
              placeholder="First name"
              value={state.first_name}
              onChange={onInputChange}
              isChanged={isInputChanged.first_name}
              isLoginPage
            />
            <ValidationInput
              type="text"
              name="last_name"
              placeholder="Last name"
              value={state.last_name}
              onChange={onInputChange}
              isChanged={isInputChanged.last_name}
              isLoginPage
            />
            <ValidationInput
              type="email"
              name="email"
              placeholder="Work Email"
              value={state.email}
              onChange={onInputChange}
              isChanged={isInputChanged.email}
              isLoginPage
            />
            <Styled.InputWrapper>
              <ValidationInput
                type={isRevealPassword.password ? "text" : "password"}
                name="password"
                placeholder="Password"
                value={state.password}
                onChange={onInputChange}
                isChanged={isInputChanged.password}
                isLoginPage
              />
              <Styled.TogglePassword onClick={() => togglePassword("password")}>
                {state.isRevealPassword ? (
                  <span className="fas fa-eye field-icon toggle-password" />
                ) : (
                  <span className="fas fa-eye-slash field-icon toggle-password" />
                )}
              </Styled.TogglePassword>
            </Styled.InputWrapper>
            <Styled.InputWrapper>
              <ValidationInput
                type={isRevealPassword.confirm_password ? "text" : "password"}
                name="confirm_password"
                placeholder="Confirm password"
                value={state.confirm_password}
                onChange={onInputChange}
                isChanged={isInputChanged.confirm_password}
                isLoginPage
              />
              <Styled.TogglePassword onClick={() => togglePassword("confirm_password")}>
                {state.isRevealPassword ? (
                  <span className="fas fa-eye field-icon toggle-password" />
                ) : (
                  <span className="fas fa-eye-slash field-icon toggle-password" />
                )}
              </Styled.TogglePassword>
            </Styled.InputWrapper>
            {/* <Styled.Spacer /> */}
            <Styled.Button onClick={onSubmitButtonClick}>Send</Styled.Button>
            {error && <Styled.ErrorText>{error}</Styled.ErrorText>}
          </Styled.StyledForm>

          {window.innerWidth < 940 && <Styled.SwitchButton onClick={onBackButtonClick}>Back</Styled.SwitchButton>}
        </>
      )}
    </Styled.LeftSide>
  );
}

import basicConfig from "./basicConfig";

const _ = undefined;

const planAPI = {
  getPlans() {
    return basicConfig.createRequest("/plans", "GET").then((res) => res.data);
  },

  adminGetPlans() {
    return basicConfig.createRequest("/v1/admin/plans", "GET").then((res) => res.data);
  },

  createPlan(payload) {
    return basicConfig
      .createRequest("/plan/create", "POST", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },

  deletePlan(id) {
    return basicConfig.createRequest(`/plan/delete/${id}`, "DELETE").then((res) => res.data);
  },

  updatePlan(id, payload) {
    return basicConfig
      .createRequest(`/plan/update/${id}`, "PATCH", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },

  adminUpdatePlan(id, payload) {
    return basicConfig
      .createRequest(`/v1/admin/plans/${id}`, "PATCH", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },

  adminCreatePlan(payload) {
    return basicConfig
      .createRequest("/v1/admin/plans", "POST", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },

  adminDeletePlan(id) {
    return basicConfig.createRequest(`/v1/admin/plans/${id}`, "DELETE").then((res) => res.data);
  },

  validateCoupon(payload) {
    return basicConfig
      .createRequest("/v1/coupon/validate", "POST", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },

  adminGetAllCoupons(params) {
    return basicConfig.createRequest("/v1/admin/coupons", "GET", _, _, _, params).then((res) => res.data);
  },

  adminFilterCoupons(payload) {
    return basicConfig
      .createRequest("/v1/admin/coupons/filter", "POST", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },

  cancelSubscription() {
    return basicConfig.createRequest("/v1/customer/subscription/cancel", "DELETE").then((res) => res.data);
  },

  updateSubscription(payload) {
    return basicConfig
      .createRequest("/v1/customer/subscription/update", "PATCH", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },

  adminUpdateCoupon(id, payload) {
    return basicConfig
      .createRequest(`/v1/admin/coupons/${id}`, "PATCH", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },

  adminCreateCoupon(payload) {
    return basicConfig
      .createRequest("/v1/admin/coupons", "POST", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },
};

export default planAPI;

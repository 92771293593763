import React from "react";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import { LangSwitcher } from "components/presenters";
import { QUERIES } from "configs/styles/common";
import { useBooleanState } from "services/hooks";

import { MobileMenu, NavigationLink, Logo, MyAccount } from "./components";
import * as Styled from "./style";

export function Header() {
  const isTabletOrMobile = useMediaQuery({ query: QUERIES.upToTablet });
  const isDesktop = !isTabletOrMobile;
  const isAuthorized = Cookies.get("isLogin") === "true";

  const [isMenuOpen, setNotMenuOpen, setIsMenuOpen] = useBooleanState();

  const { t } = useTranslation();

  return (
    <>
      {isTabletOrMobile && (
        <>
          <Styled.HeaderSpace />
          <Styled.HeaderWrapper>
            <Styled.Header>
              <Logo />

              <Styled.HeaderBox>
                <Styled.SwitcherWrapper isAuthorized={isAuthorized}>
                  <LangSwitcher />
                </Styled.SwitcherWrapper>

                <Styled.Menu onClick={setIsMenuOpen} aria-label="menu" />
              </Styled.HeaderBox>
            </Styled.Header>
          </Styled.HeaderWrapper>

          <MobileMenu open={isMenuOpen} close={setNotMenuOpen} />
        </>
      )}

      {isDesktop && isAuthorized && (
        <>
          <Styled.HeaderWrapper>
            <Styled.Header>
              <Styled.MainNavigation>
                <Logo />
                <NavigationLink to="/single-search">{t("Search")}</NavigationLink>
              </Styled.MainNavigation>

              <Styled.MainNavigation smallGap>
                <MyAccount />
                <LangSwitcher />
              </Styled.MainNavigation>
            </Styled.Header>
          </Styled.HeaderWrapper>
        </>
      )}

      {isDesktop && !isAuthorized && (
        <>
          <Styled.HeaderWrapper>
            <Styled.Header>
              <Styled.MainNavigation>
                <Logo />
                <NavigationLink to="/home">{t("Home")}</NavigationLink>
              </Styled.MainNavigation>
              <Styled.MainNavigation smallGap>
                <NavigationLink to="/sign-in">{t("Sign In")}</NavigationLink>

                <Styled.LangWrapper>
                  <LangSwitcher />
                </Styled.LangWrapper>
              </Styled.MainNavigation>
            </Styled.Header>
          </Styled.HeaderWrapper>
        </>
      )}
    </>
  );
}

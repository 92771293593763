import { useLayoutEffect, useState, useRef } from "react";
import { useAlert } from "react-alert";

export default function useError() {
  const alert = useAlert();
  const [error, setError] = useState();

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    alert.error(error);
  }, [error, alert]);

  return [setError];
}

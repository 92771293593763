import basicConfig from "./basicConfig";

const commonAPI = {
  getOptionData(url) {
    return basicConfig
      .createRequest(`/${url}`, "GET", {
        "content-type": "application/json",
      })
      .then((res) => res.data);
  },

  contactUs(data) {
    return basicConfig
      .createRequest("/contact-us", "POST", { "content-type": "application/json" }, data)
      .then((res) => res.data);
  },

  getReportTypes() {
    return basicConfig.createRequest("/report_types", "GET").then((res) => res.data);
  },

  getImageProviders() {
    return basicConfig.createRequest("/image-providers", "GET").then((res) => res.data);
  },
};

export default commonAPI;

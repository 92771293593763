import React, { Suspense, lazy, useEffect } from "react";
import ReactGA from "react-ga";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { ErrorBoundary } from "react-error-boundary";

import AdminSwitch from "admin/AdminSwitch";
import { ScrollToTop } from "services/helpers/common";
import { PdfExportPage, SingleSearchPage, HomePage, Terms, CookieNotice, Privacy, Login, NotFoundPage } from "pages";

import { MapProvider } from "./providers/business";
import { CookieBanner, ErrorFallback } from "../components/presenters";
import { SEO } from "../configs/seo";
import { BaseRoute, AdminRoute, PrivateRoute, PublicRoute } from "../services/helpers/routing";
import { GlobalStyle } from "./styles";

const AdminLogin = lazy(() => import("../admin/AdminLogin"));

const options = {
  timeout: 3000,
  position: positions.BOTTOM_CENTER,
  containerStyle: {
    zIndex: 1100,
    lineHeight: "20px",
    fontSize: "0.9rem",
  },
};

function App() {
  useEffect(() => {
    if (process.env.REACT_APP_GA_ENABLED === "true") {
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, []);

  return (
    <Router>
      <GlobalStyle />
      <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => window.location.reload(true)}>
        <Suspense fallback={<div />}>
          <Provider template={AlertTemplate} {...options}>
            <div className="App">
              <SEO title={"GeoX Property Intelligence"} titleTemplate="" />
              <CookieBanner />
              <ScrollToTop>
                <Switch>
                  <PublicRoute path="/" exact component={HomePage} />

                  <BaseRoute path="/admin/login" component={AdminLogin} />

                  <AdminRoute path="/admin" component={AdminSwitch} />

                  <BaseRoute path="/login" component={Login} />

                  <BaseRoute path="/cookie-notice" component={CookieNotice} />

                  <BaseRoute path="/privacy" component={Privacy} />
                  <BaseRoute path="/terms" component={Terms} />

                  <PublicRoute path="/home" component={HomePage} />

                  <MapProvider>
                    <PrivateRoute path="/pdf-export" component={PdfExportPage} />
                    <PrivateRoute exact path="/single-search" component={SingleSearchPage} />
                    <PrivateRoute path="/single-search/:lat/:lng/data_type=:data_type" component={SingleSearchPage} />
                    <PrivateRoute path="/single-search/:address/data_type=:data_type" component={SingleSearchPage} />
                  </MapProvider>

                  <Route path="*" component={NotFoundPage} />
                </Switch>
              </ScrollToTop>
            </div>
          </Provider>
        </Suspense>
      </ErrorBoundary>
    </Router>
  );
}

export default App;

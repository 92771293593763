import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import Cookies from "js-cookie";
import { useAlert } from "react-alert";

import { useBooleanState } from "services/hooks";
import { ReactComponent as LogoutSVG } from "assets/icons/logout.svg";
import API from "api/apiClient";
import { S3 } from "services/constants";

import { Avatar } from "../Avatar";
import * as Styled from "./style";
const CollapseBoldSVG = S3 + "assets/shared/icons/collapse.svg";
const userImg = S3 + "assets/user.png";

export function MyAccount() {
  const [fullName, setFullName] = useState("");
  const [isDropdownShowing, closeDropdown, openDropdown, toggleDropdown] = useBooleanState(false);
  const navRef = useRef(null);
  const alert = useAlert();

  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (Cookies.get("isLogin") === "true") {
      API.getUserBasicInfo().then((data) => {
        setFullName(data.data.full_name);
      });
    }
  }, []);

  function logOut() {
    API.logout().then((data) => {
      if (data.status === 200) {
        Cookies.remove("isAdmin");
        history.push("/home");
      }
      alert.show(data.msg);
    });
  }

  return (
    <Styled.Wrapper>
      <Styled.AccountButton onClick={toggleDropdown} data-testid="my-account">
        <Avatar avatar={userImg} alt={fullName} />
        <Styled.Chevron src={CollapseBoldSVG} alt="open-close dropdown" isDropdownShowing={isDropdownShowing} />
      </Styled.AccountButton>

      <CSSTransition
        in={isDropdownShowing}
        timeout={500}
        classNames="dropdown"
        unmountOnExit
        nodeRef={navRef}
        onEnter={openDropdown}
        onExited={closeDropdown}
      >
        <Styled.Nav ref={navRef}>
          <Styled.AccountList>
            <Styled.ListItem>
              <Styled.User>
                {t("Hi")}, {fullName}
              </Styled.User>
            </Styled.ListItem>

            <Styled.ListItem>
              <Styled.IconWrap>
                <LogoutSVG style={{ width: "22px", marginLeft: "2px" }} />
              </Styled.IconWrap>
              <Styled.Logout onClick={logOut}>{t("Log Out")}</Styled.Logout>
            </Styled.ListItem>
          </Styled.AccountList>
        </Styled.Nav>
      </CSSTransition>
    </Styled.Wrapper>
  );
}

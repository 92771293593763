import React from "react";
import { v4 as uuid } from "uuid";

import styles from "./Text.module.css";

export default function Text({ inputText, labelText, type }) {
  const id = uuid();
  return (
    <>
      <label htmlFor={id}>{labelText}</label>
      <input id={id} data-formtype={type} className={"textInputs " + styles.input} placeholder={inputText} />
    </>
  );
}

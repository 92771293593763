import React from "react";
import { Link, useHistory } from "react-router-dom";
import Cookies from "js-cookie";

import styles from "./AdminHeader.module.css";
import { S3 } from "../../services/constants/API";
import API from "../../api/apiClient";

import "../Admin.css";

export default function AdminHeader() {
  const history = useHistory();

  const logOut = () => {
    API.logout().then((data) => {
      if (data.status === 200) {
        Cookies.remove("role");
        history.push("/admin/login");
      }
    });
  };

  return (
    <header className={styles.AdminHeader}>
      <nav>
        <Link to="/admin">
          <div className="admin-logo">
            <img src={S3 + "logo.png"} alt="" />
          </div>
        </Link>
        <div className={"dropdown show " + styles.dropdownHeader}>
          <button
            className="btn btn-secondary dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Managing
          </button>

          <div className="admin_dropdown-menu dropdown-menu" aria-labelledby="dropdownMenuButton">
            <Link className="admin_dropdown-item dropdown-item" to="/admin/manage-users">
              Users
            </Link>
            <Link className="admin_dropdown-item dropdown-item" to="/admin/manage-plans">
              Plans
            </Link>
            <Link className="admin_dropdown-item dropdown-item" to="/admin/manage-projects">
              Projects
            </Link>
            <Link className="admin_dropdown-item dropdown-item" to="/admin/manage-coupons">
              Coupons
            </Link>
          </div>
        </div>

        <Link to="/admin/manage-orders">Orders</Link>

        <Link to="/admin/contact-us">Contact us</Link>

        <Link to="/admin/feedback">Feedback</Link>

        <Link to="/admin/bills">Bills</Link>

        <div className={"dropdown show " + styles.dropdownHeader}>
          <button
            className="btn btn-secondary dropdown-toggle"
            type="button"
            id="dropdownMenuButton2"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Analytics
          </button>

          <div className="admin_dropdown-menu dropdown-menu" aria-labelledby="dropdownMenuButton2">
            <Link className="admin_dropdown-item dropdown-item" to="/admin/analytics/login-activity">
              Login Activity
            </Link>
          </div>
        </div>
      </nav>
      <div>
        <button className={styles.logOut} onClick={logOut} type="button">
          Sign Out
        </button>
      </div>
    </header>
  );
}

import React, { useState } from "react";

import useLoadMore from "services/hooks/useLoadMore";
import useError from "services/hooks/useError";
import useAdminLogic from "services/hooks/useAdminLogic";

import ManageBillsView from "./ManageBillsView";

export default function ManageBills() {
  const [visible, setVisible] = useState(false);

  const [setError] = useError();

  const filterConfig = [
    { value: "status", isArray: true },
    { value: "plan_name", isArray: true },
    { value: "subscription_type", isArray: true },
  ];
  const [bills, filters, setFilters, filtersValues, filteringState, loadMore, debouncedSearch] = useAdminLogic(
    "bill-records",
    "adminFilterBills",
    filterConfig,
    setError
  );

  const [isLoaded] = useLoadMore(bills);

  return (
    <>
      <ManageBillsView
        bills={bills}
        visible={visible}
        setVisible={setVisible}
        filters={filters}
        setFilters={setFilters}
        loadMore={loadMore}
        isLoaded={isLoaded}
        filtersValues={filtersValues}
        filteringState={filteringState}
        debouncedSearch={debouncedSearch}
      />
    </>
  );
}

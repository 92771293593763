export const getTextFields = () => {
  const textFilters = document.querySelectorAll(".textInputs");
  const filtersValues = {};
  textFilters.forEach((el) => {
    filtersValues[el.getAttribute("data-formtype")] = el.value;
  });
  return filtersValues;
};

export const getFiniteFields = () => {
  const finiteFilters = document.querySelectorAll(".finiteInputs");
  const filtersValues = {};
  finiteFilters.forEach((el) => {
    if (el.selectedIndex) filtersValues[el.getAttribute("data-formtype")] = el.value;
  });
  return filtersValues;
};

export const getMultipleFiniteFields = () => {
  const multipleFiniteFilters = document.querySelectorAll(".multipleFiniteInputs");
  const filtersValues = {};
  multipleFiniteFilters.forEach((el) => {
    const value = el.getAttribute("data-selected");
    filtersValues[el.getAttribute("data-formtype")] = value.split(",").join(", ");
  });
  return filtersValues;
};

export const getArrangeFields = () => {
  const arrangeFilters = document.querySelectorAll(".arrangeInputs");
  const filtersValues = {};
  arrangeFilters.forEach((el) => {
    if (!filtersValues[el.getAttribute("data-formtype")]) filtersValues[el.getAttribute("data-formtype")] = "";
    if (el.value) {
      filtersValues[el.getAttribute("data-formtype")] += `${el.getAttribute("data-arrange")} ${el.value} `;
    }
  });
  return filtersValues;
};

export const clearInputs = (callback = () => {}) => {
  const inputs = document.querySelectorAll("input");
  inputs.forEach((input) => {
    input.value = "";
  });

  const selects = document.querySelectorAll("select");
  selects.forEach((select) => {
    if (select.nextSibling) select.nextSibling.click();
    select.selectedIndex = 0;
  });

  callback(false);
};

import basicConfig from "./basicConfig";

const _ = undefined;

const orderAPI = {
  getAllOrders() {
    return basicConfig.createRequest("/order/list", "GET").then((res) => res.data);
  },

  orderProject(project_id) {
    const params = {
      project_id,
    };

    return basicConfig
      .createRequest("/order/place", "POST", { "content-type": "application/json" }, _, _, params)
      .then((res) => res.data);
  },

  filterOrders(payload) {
    return basicConfig
      .createRequest("/order/filtered", "POST", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },

  adminGetAllOrders(payload) {
    return basicConfig.createRequest("/v1/admin/orders", "GET", _, _, _, payload).then((res) => res.data);
  },

  adminFilterOrders(payload) {
    return basicConfig
      .createRequest("/v1/admin/orders/filter", "POST", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },

  adminFilterBills(payload) {
    return basicConfig
      .createRequest("/v1/admin/bill/filter", "POST", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },

  fetchCreditStatements() {
    return basicConfig
      .createRequest("/v1/admin/credits-statements", "GET", { "content-type": "application/json" })
      .then((res) => res.data);
  },
  adminFilterCreditStatements(payload) {
    return basicConfig
      .createRequest("/v1/admin/credits-statements/filter", "POST", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },
};

export default orderAPI;

import React from "react";
import { v4 as uuid } from "uuid";
import Sidebar from "react-sidebar";

import AdminHeader from "../AdminHeader";
import styles from "./ManageBills.module.css";
import SidebarForm from "./SidebarForm";
import "../Admin.css";

export default function ManageBillsView({
  bills = [],
  filtersValues,
  filteringState,
  visible,
  setVisible,
  setFilters,
  filters,
  loadMore,
  isLoaded,
  debouncedSearch,
}) {
  const appliedFilters = [];

  for (const filter in filters) {
    appliedFilters.push([filter, filters[filter]]);
  }

  return (
    <>
      <AdminHeader />
      <main className={styles.ManageBills}>
        <h1>Manage bills</h1>
        <div className="searchPanel">
          <div className="searchContainer">
            <input
              placeholder="Search"
              className="search"
              onChange={({ target: { value } }) => debouncedSearch(value)}
            />
            <i className="fas fa-search" />
          </div>

          <div role="presentation" className="filtersContainer" onClick={() => setVisible(true)}>
            <span>Filters</span>
            <i className="fas fa-filter" />
          </div>
        </div>
        <div className="filterPanel">
          {appliedFilters.map((filter) => {
            if (filter[1]) {
              return (
                <span key={uuid()} className="filter">
                  <span>{filter[0]}</span>: {filter[1]}
                </span>
              );
            }
            return null;
          })}
        </div>
        <div className="table-responsive tableContainer">
          {!isLoaded && (
            <button className={styles.loadMore} onClick={loadMore} type="button">
              Load more
            </button>
          )}
          <table className="table">
            <thead>
              <tr>
                <th>
                  <i className="fas fa-user" /> Customer email
                </th>
                <th>
                  <i className="fas fa-user" /> Customer full name
                </th>
                <th>
                  <i className="far fa-clock" /> Plan
                </th>
                <th>
                  <i className="fas fa-signature" /> Invoice ID
                </th>
                <th>
                  <i className="fas fa-money-bill" /> Status{" "}
                </th>
                <th>
                  <i className="fas fa-clock" /> Purchase date{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              {bills.map((bill) => {
                return (
                  <tr key={uuid()}>
                    <td>{bill.customer_email}</td>
                    <td>{bill.customer_first_name + " " + bill.customer_last_name}</td>
                    <td>{bill.plan_name + " " + bill.subscription_type}</td>
                    <td>{bill.invoice_link}</td>
                    <td>{bill.status}</td>
                    <td>{bill.purchase_date}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Sidebar
          sidebar={
            <SidebarForm
              filtersValues={filtersValues}
              apply={setVisible}
              setFilters={setFilters}
              submitFilter={filteringState}
            />
          }
          open={visible}
          onSetOpen={setVisible}
          styles={{
            root: {
              visibility: visible ? "visible" : "hidden",
              opacity: visible ? "1" : "0",
            },
          }}
          pullRight
          overlayClassName={styles.overlay}
        />
      </main>
    </>
  );
}
